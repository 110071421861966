/**
 * src/components/Loading.js
 *
 * @module Loading
 * @param {string} loading - a loading message
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
// import fire_engine from '../images/fire-engine.png'

const Bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0.8);
  } 40% {
    transform: scale(1.0);
  }
`

const Wrapper = styled.div`
  display: block;
  padding: 1rem 7rem;
  img#engine {
    margin: 0 auto;
  }
  animation: ${Bounce} 1s infinite ease-in-out both;
  &:first-child {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`

// src={fire_engine}
const Loading = ({ loading }) => {
  return (
    <Wrapper>
      <img
        id="engine"
        src="https://d76b2d0qyue8q.cloudfront.net/media/fire-engine.png"
        alt="fire_engine"
        width="200"
        height="39"
      />
    </Wrapper>
  )
}

Loading.propTypes = {
  loading: PropTypes.string
}

Loading.displayName = 'Loading'

export default Loading
