//@ts-nocheck

/**
 * ThemeContext.js
 *
 */

import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'

import {
  backgroundColor,
  textColor,
  fontFamily,
  sectionHeading
} from './themes/theme'
import { mediaAbove, mediaBelow } from './utilities'
import { getSunsetSunrise } from './utilities/Sunset'
import GlobalStyles from './themes/globalStyles'

const ThemeToggleContext = React.createContext()

export const useTheme = () => React.useContext(ThemeToggleContext)

const DEBUG = Boolean(process.env.DEBUG)
const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

// These styles will be applied throughout the app
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${backgroundColor};
  color: ${textColor};
  font-family: ${fontFamily};
  h3 {
    color: ${sectionHeading};
  }
  ${mediaAbove.desktop`
    max-width: 1284px;
    min-width: 992px;
    `}
  ${mediaBelow.desktop`
    max-width: 732px;
    `}
  ${mediaBelow.tablet`
    max-width: 512px;
  `}
`

export const MyThemeProvider = ({ children }) => {
  const initialMode = 'dark'
  const [themeState, setThemeState] = useState({ mode: initialMode })

  // TODO: This is currently fixed to East Coast time zone. Need to fix this to
  // the Department's lat/long.  Or, using the user's timezone offset, use
  // https://github.com/romanyanke/daynight package to determine day/night.
  const latitude = 41.12
  const longitude = -73.26

  log.debug('themecontext-department.lat: ', latitude)
  log.debug('themecontext-department.long: ', longitude)
  // Set initial themeState by determing if it is before/after sunrise/sunset
  useEffect(() => {
    const { sunset, sunrise, now } = getSunsetSunrise()
    if (sunset > now && now > sunrise) {
      // i.e. 6 am until 6 pm
      setThemeState({ mode: 'light' })
      log.debug(
        '1 sunset > now && now > sunrise: ',
        sunset > now && now > sunrise
      )
    } else if (sunset < now && now > sunrise) {
      // i.e. 6 pm until midnight
      setThemeState({ mode: 'dark' })
      log.debug(
        '2 sunset < now && now > sunrise: ',
        sunset < now && now > sunrise
      )
    } else if (sunset > now && now < sunrise) {
      // i.e. midnight until 6 am
      setThemeState({ mode: 'dark' })
      log.debug(
        '3 sunset > now && now < sunrise: ',
        sunset > now && now < sunrise
      )
    } else {
      setThemeState({ mode: 'light' })
      log.debug('4 default dark/light setting of light')
    }
  }, [])

  // User toggle of dark/light via themeState
  const toggle = () => {
    const mode = themeState.mode === 'light' ? `dark` : `light`
    setThemeState({ mode: mode })
  }

  return (
    <ThemeToggleContext.Provider value={{ toggle: toggle }}>
      <GlobalStyles />
      <ThemeProvider
        theme={{
          mode: themeState.mode
        }}
      >
        <Wrapper>{children}</Wrapper>
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  )
}

MyThemeProvider.displayName = 'App'

MyThemeProvider.propTypes = {
  children: PropTypes.any
}
