//@ts-nocheck

/**
 * src/queries/users.js
 *
 */

import { gql } from '@apollo/client'

const USERS_FETCH_BY_DEPT = gql`
  query UsersDept($dept_id: ID) {
    track_department_users(dept_id: $dept_id) {
      dept_id
      user {
        user_id
        uslug
        first_name
        last_name
        mobile_num
        carrier_id
        carrier {
          carrier_name
          gateway
        }
        email
        device_os
        rank
        default_station
        is_driver
        is_enabled
        is_sleeping
        is_admin
        is_deleted
        is_career
        is_volley
        is_engine
        is_beta_tester
        created_at
        updated_at
      }
    }
  }
`

const USER_FETCH_BY_EMAIL = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      user_id
      first_name
      last_name
      mobile_num
      email
      device_os
      rank
      default_station
      is_driver
      is_enabled
      is_sleeping
      is_admin
      is_deleted
      is_career
      is_volley
      is_engine
      carrier {
        carrier_name
      }
      department {
        dept_id
      }
    }
  }
`

const USER_FETCH = gql`
  query User($user_id: ID!) {
    user(user_id: $user_id) {
      user_id
      first_name
      last_name
      mobile_num
      email
      device_os
      rank
      default_station
      is_driver
      is_enabled
      is_sleeping
      is_admin
      is_deleted
      is_career
      is_volley
      is_engine
      carrier {
        carrier_name
      }
      department {
        dept_id
      }
    }
  }
`

const USER_FETCH_BY_TOKEN = gql`
  query UserByToken($token: String!) {
    userByToken(token: $token) {
      user_id
      first_name
      last_name
      mobile_num
      email
      is_enabled
      reset_password_token
      reset_password_expires
    }
  }
`

const USER_FETCH_BY_USLUG = gql`
  query UserByUslug($uslug: String!) {
    userByUslug(uslug: $uslug) {
      user_id
      uslug
      first_name
      last_name
      mobile_num
      email
      device_os
      rank
      default_station
      is_driver
      is_enabled
      is_sleeping
      is_admin
      is_deleted
      is_career
      is_volley
      is_engine
      is_beta_tester
      use_most_recent
      carrier {
        carrier_name
        carrier_id
      }
      department {
        dept_id
      }
      severity {
        severity
      }
      station {
        sta_id
        sta_abbr
        sta_name
        latitude
        longitude
      }
      track_apparatus_by_user {
        apparatus {
          app_id
          app_name
        }
      }
    }
  }
`

export { USER_FETCH, USERS_FETCH_BY_DEPT, USER_FETCH_BY_EMAIL, USER_FETCH_BY_TOKEN, USER_FETCH_BY_USLUG }
