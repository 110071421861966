/**
 * src/components/Heading.js
 *
 * Usage:
 *
   <Heading h2 center>
     Welcome to FireTek
   </Heading>

   Props: center, noMargin, right, h2, h3, h4, h5
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const baseStyle = css`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: ${(props) => props.noMargin && '0'};
  text-align: ${(props) => {
    if (props.center) return 'center'
    if (props.right) return 'right'
    return 'left'
  }};
  background: ${(props) => {
    if (props.background) return props.background
    else if (props.backgroundDark) return 'var(--dark-bg)'
    else if (props.backgroundLight) return 'var(--light-bg)'
    return 'inherit'
  }};
  color: ${(props) => {
    if (props.color) return props.color
    if (props.colorDark) return 'var(--dark-fg)'
    else if (props.colorLight) return 'var(--light-fg)'
    return 'inherit'
  }};

  max-width: 100%;
`

const HeadingOne = styled.h1`
  font-size: 42px;
  font-weight: bold;
  ${baseStyle};
  @media (max-width: 480px) {
    font-size: 40px;
  }
`

const HeadingTwo = styled.h2`
  font-size: 36px;
  font-weight: bold;
  ${baseStyle};
`

const HeadingThree = styled.h3`
  font-size: 28px;
  font-weight: bold;
  ${baseStyle};
`

const HeadingFour = styled.h4`
  font-size: 22px;
  font-weight: bold;
  ${baseStyle};
`

const HeadingFive = styled.h5`
  font-size: 18px;
  font-weight: bold;
  ${baseStyle};
`

const Heading = ({ h2, h3, h4, h5, noMargin, right, center, ...props }) => {
  if (h2) return <HeadingTwo noMargin={noMargin} right={right} center={center} {...props} />
  if (h3) return <HeadingThree noMargin={noMargin} right={right} center={center} {...props} />
  if (h4) return <HeadingFour noMargin={noMargin} right={right} center={center} {...props} />
  if (h5) return <HeadingFive noMargin={noMargin} right={right} center={center} {...props} />
  return <HeadingOne noMargin={noMargin} right={right} center={center} {...props} />
}

Heading.propTypes = {
  center: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  noMargin: PropTypes.bool,
  right: PropTypes.bool
}

export default Heading
