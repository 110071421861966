/**
 * src/components/Flex.js
 *
 * Usage:
 *
     <Flex column alignCenter justifyCenter contentCenter noPadding>
 */

import styled from 'styled-components'

// flex: 1 0 auto;
// flex-grow: 1;
// flex-basis: auto;
const Flex = styled.div`
  display: flex;
  flex: 1 0 auto;

  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius
    else if (props.borderRadiusAll) return '1rem'
    return 'inherit'
  }};
  flex-wrap: ${props => {
    if (props.wrapReverse) return 'wrap-reverse'
    else if (props.noWrap) return 'nowrap'
    return 'wrap'
  }};
  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent
    if (props.justifyCenter) return 'center'
    else if (props.justifyAround) return 'space-around'
    else if (props.justifyBetween) return 'space-between'
    else if (props.justifyEvenly) return 'space-evenly'
    else if (props.justifyEnd) return 'flex-end'
    return 'flex-start'
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems
    else if (props.alignStretch) return 'stretch'
    else if (props.alignEnd) return 'flex-end'
    if (props.alignCenter) return 'center'
    else if (props.alignBaseline) return 'baseline'
    return 'flex-start'
  }};
  align-content: ${props => {
    if (props.alignContent) return props.content
    else if (props.contentStart) return 'flex-start'
    else if (props.contentEnd) return 'flex-end'
    else if (props.contentCenter) return 'center'
    else if (props.contentBetween) return 'space-between'
    else if (props.contentAround) return 'contentAround'
    return 'stretch'
  }};
  background: ${props => {
    if (props.background) return props.background
    else if (props.backgroundDark) return 'var(--dark)'
    else if (props.backgroundLight) return 'var(--light)'
    return 'inherit'
  }};
  color: ${props => {
    if (props.color) return props.color
    if (props.colorDark) return 'var(--dark)'
    else if (props.colorLight) return 'var(--light)'
    return 'inherit'
  }};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  padding: ${props => {
    if (props.padding) return props.padding
    else if (props.noPadding) return '0'
    else if (props.paddingVertical) return '1rem 0'
    else if (props.paddingHorizontal) return '0 1rem'
    else if (props.paddingAll) return '1rem 1rem'
    return 'inherit'
  }};
  margin: ${props => {
    if (props.margin) return props.margin
    else if (props.marginAuto) return 'auto'
    else if (props.marginNone) return '0'
    else if (props.marginVertical) return '1rem 0'
    else if (props.marginHorizontal) return '0 1rem'
    else if (props.marginAll) return '1rem 1rem'
    return 'inherit'
  }};
`

export const ColumnWidth = styled.div`
  width: ${props => {
    if (props.three) return '33.33%'
    if (props.four) return '25%'
    return '50%'
  }};
  padding: ${props => (props.noPadding ? 0 : '15px')};
`

export default Flex
