//@ts-nocheck

/**
 * src/containers/Maps/MapBoxSat.js
 *
 * @module MapBoxSat
 */

import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { getHydrants } from './getHydrants'
import { useGetAppliances } from '../../hooks/use-get-appliances'
const fireHydrantIcon = `https://s3.us-east-1.amazonaws.com/io.firetek.assets/hydrants/json/hydrantIcon.png`
// const fireHydrantIcon = `https://s3.us-east-1.amazonaws.com/io.firetek.assets/hydrants/json/hydrantIcon.webp`

const DEBUG = process.env.DEBUG
const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN
const NODE_ENV = process.env.NODE_ENV
const HYDRANT_RADIUS_IN_FT = 1200

import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
mapboxgl.workerCount = 2

const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

if (NODE_ENV !== 'production') {
  log.debug('NODE_ENV at MapBoxSat: ', NODE_ENV)
  log.debug('DEBUG at MapBoxSat: ', DEBUG)
}

const ErrorContainer = styled.div`
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 1rem;
`

// touch-action: none;
// add this to MapBoxSatDiv if you want to disable scrolling within the Map
const MapBoxSatDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vw;
  margin: 0 0 0.2em 0;

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 100vw;
  }

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    height: 100vw;
  }
`

const NavigateButton = styled.div`
  align-items: center;
  background: var(--fire-engine-blue);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  display: flex;
  margin: 0.5%;
  padding: 0.3rem 0.5rem;
  position: inherit;
  text-align: center;
  z-index: 4;
  &:link {
    background: var(--fire-engine-blue);
  }
  &:visited {
    background: green;
  }
  &:hover {
    background: rgba(217, 23, 14, 1);
  }
  &:active {
    background: rgba(217, 23, 14, 1);
  }
  a {
    color: white;
    text-decoration: none;
  }
`

const HydrantRadiusButton = styled(NavigateButton)`
  background: rgba(217, 23, 14, 0.85);
  font-size: 0.7rem;
  font-weight: normal;
  height: 1.3rem;
  padding: 0.2rem 0.5rem;
  width: max-content;
`

const MapBoxSat = ({ inc, userLocation }) => {
  const mapSatContainerRef = useRef(null)
  // const [SVButtonDisplay, setSVButtonDisplay] = useState('hidden')
  const [isError, setIsError] = useState(false)
  // const [toggleSVMap, setToggleSVMap] = useState(false)

  const {
    department: { dept_abbr, dept_id }
  } = inc
  // let deptAbbr = dept_abbr.toLowerCase()
  let dLng = inc.longitude
  let dLat = inc.latitude
  let map
  let usrLat
  let usrLng

  const errorMap = (err) => {
    log.error('ERROR: ' + err)
    setIsError(true)
  }

  const {
    data: dataAppl,
    error: errorAppl,
    loading: loadingAppl
  } = useGetAppliances(
    parseInt(dept_id),
    'wet',
    { lat: parseFloat(dLat), lon: parseFloat(dLng) },
    parseFloat(0.5)
  )

  if (dataAppl) {
    log.debug('🐨 dataAppl: ', dataAppl)
  } else if (errorAppl) {
    errorMap(errorAppl)
  } else if (loadingAppl) {
    log.debug('loadingAppl: ', loadingAppl)
  }

  // Round to 4 digits to prevent too many rerenders when using watchPosition
  if (userLocation) {
    usrLat = userLocation.latitude.toFixed(4)
    usrLng = userLocation.longitude.toFixed(4)
  } else {
    log.warn('WARNING: no GPS provided to userLocation variable')
  }

  // Creates a circle around a point with a given radius
  const createGeoJSONCircle = function (center, radiusInKm, points) {
    if (!points) points = 64

    let coords = {
      latitude: parseFloat(center[1]),
      longitude: parseFloat(center[0])
    }

    let ret = []
    let distanceX =
      radiusInKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
    let distanceY = radiusInKm / 110.574

    let theta, x, y
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI)
      x = distanceX * Math.cos(theta)
      y = distanceY * Math.sin(theta)

      ret.push([coords.longitude + x, coords.latitude + y])
    }
    ret.push(ret[0])

    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [ret]
        }
      }
    }
  }

  const buildMap = async () => {
    try {
      map = await new mapboxgl.Map({
        attributionControl: false,
        container: mapSatContainerRef.current,
        // style: 'mapbox://styles/mapbox/satellite-v9',
        // style: 'mapbox://styles/mapbox/satellite-streets-v11',
        style: 'mapbox://styles/firetek/cklpoylgq2f2517pevh7u00tu',
        // style: 'mapbox://styles/mapbox/navigation-preview-night-v4',
        // style: 'mapbox://styles/mapbox/streets-v11',
        // style: 'mapbox://styles/mapbox/navigation-preview-day-v4',
        center: [dLng, dLat],
        pitch: 0,
        zoom: 17,
        scrollZoom: false,
        boxZoom: false,
        dragPan: false
      })
    } catch (err) {
      errorMap(err)
    }

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl())

    // let geolocate = new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true
    //   },
    //   trackUserLocation: true
    // })
    // // Add geolocate control to the map
    // map.addControl(geolocate)

    new mapboxgl.Marker({ color: '#f00' }).setLngLat([dLng, dLat]).addTo(map)
    if (usrLng && usrLat) {
      new mapboxgl.Marker().setLngLat([usrLng, usrLat]).addTo(map)
    }

    // On mobile devices we need two fingers to pan
    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    // ) {
    map.dragPan.disable()
    map.scrollZoom.disable()
    map.touchPitch.disable()
    map.on('touchstart', function (e) {
      var oe = e.originalEvent
      if (oe && 'touches' in oe) {
        if (oe.touches.length > 1) {
          oe.stopImmediatePropagation()
          map.dragPan.enable()
        } else {
          map.dragPan.disable()
        }
      }
    })
    // }

    map.on('load', function () {
      if (usrLng && usrLat) {
        map.addLayer({
          id: 'start',
          type: 'circle',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: [usrLng, usrLat]
                  }
                }
              ]
            }
          },
          paint: {
            'circle-radius': 14,
            'circle-stroke-width': 4,
            'circle-opacity': 0,
            'circle-stroke-color': '#3887be'
          }
        })
      }

      if (dLng && dLat) {
        map.addSource(
          'hydrantCircle',
          createGeoJSONCircle(
            [dLng, dLat],
            (HYDRANT_RADIUS_IN_FT * 0.3048) / 1000
          )
        )
        map.addLayer({
          id: 'end',
          type: 'circle',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: [dLng, dLat]
                  }
                }
              ]
            }
          },
          paint: {
            'circle-radius': 14,
            'circle-stroke-color': '#f00',
            'circle-stroke-width': 4,
            'circle-opacity': 0
          }
        })
        map.addLayer({
          id: 'outline',
          type: 'line',
          source: 'hydrantCircle',
          layout: {},
          paint: {
            'line-color': '#f4ac00',
            'line-width': 2
          }
        })
      }

      if (dataAppl) {
        log.debug('🐨 getHydrants dataAppl: ', dataAppl)
        const { appliancesByDeptIdAndTypeAndGPS: wetHydrants } = dataAppl
        log.debug('🐨 wetHydrants dataAppl: ', wetHydrants)

        const hydrantArray = wetHydrants.map((hydrant) => {
          return {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [hydrant.longitude, hydrant.latitude]
            }
          }
        })

        let geojsonData = {
          type: 'FeatureCollection',
          features: hydrantArray
        }
        console.log('🍎 geojsonData: ', geojsonData)
        map.loadImage(fireHydrantIcon, function (error, image) {
          if (error) throw error
          map.addImage('custom-marker', image)

          // Add a GeoJSON source
          map.addSource('points', {
            type: 'geojson',
            data: geojsonData,
            cluster: true,
            clusterMaxZoom: 1
          })

          // Add a symbol layer
          map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'points',
            layout: {
              'icon-image': 'custom-marker',
              // get the location name from the source's "location" property
              'text-field': ['get', 'location'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
            },
            paint: {
              'text-color': '#FFFFFF'
            }
          })
        })
      }

      // // Add the hydrant layer (axios call to a geojson file on S3)
      // getHydrants(dept_id, deptAbbr, dLng, dLat, function (err, data) {
      //   if (err) {
      //     errorMap(err)
      //   } else {
      //     map.loadImage(fireHydrantIcon, function (error, image) {
      //       if (error) throw error
      //       map.addImage('custom-marker', image)

      //       // Add a GeoJSON source
      //       map.addSource('points', {
      //         type: 'geojson',
      //         data: data,
      //         cluster: true,
      //         clusterMaxZoom: 1
      //       })

      //       // Add a symbol layer
      //       map.addLayer({
      //         id: 'points',
      //         type: 'symbol',
      //         source: 'points',
      //         layout: {
      //           'icon-image': 'custom-marker',
      //           // get the location name from the source's "location" property
      //           'text-field': ['get', 'location'],
      //           'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      //           'text-offset': [0, 1.25],
      //           'text-anchor': 'top'
      //         },
      //         paint: {
      //           'text-color': '#FFFFFF'
      //         }
      //       })
      //     })
      //   }
      // }) // end getHydrants
    }) // end map.on(load)

    // Resize the map after the map container is shown.
    // This prevents the problem of just the upper left tile from being
    // the only tile displayed.
    setTimeout(() => map.resize(), 300)
  }

  useEffect(() => {
    buildMap()
    // checkIfStreetView()
  }, [dLat, dLng, dataAppl])

  if (isError) return ' '
  if (!dLat || !dLng) return null

  return (
    <Fragment>
      <MapBoxSatDiv id="mapboxsat" ref={mapSatContainerRef}>
        <HydrantRadiusButton>
          hydrant radius {HYDRANT_RADIUS_IN_FT}'
        </HydrantRadiusButton>
      </MapBoxSatDiv>
      {!userLocation && (
        <ErrorContainer>
          <div>Your location could not be acquired.</div>
          <br />
          <div>
            Either you have no GPS signal at the moment, or your location
            services might be turned off.
          </div>
          <br />
        </ErrorContainer>
      )}
      {isError && (
        <ErrorContainer>Mapping encountered an error.</ErrorContainer>
      )}
    </Fragment>
  )
}

MapBoxSat.propTypes = {
  inc: PropTypes.any,
  userLocation: PropTypes.object
}

export default MapBoxSat
