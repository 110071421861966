/**
 * src/containers/Incident/Assignment.js
 *
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import styled from 'styled-components'
import { cardBorderColor } from '../../themes/theme'

const Wrapper = styled.div`
  display: block;
  justify-content: space-between;

  ul {
    list-style-type: none;
    padding: 0 1rem 0 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  li {
    border-radius: 0.6rem;
    border: 1px dotted;
    border-color: ${cardBorderColor};
    line-height: 1.5;
    margin-bottom: 0.1rem;
    padding: 0.35rem 0.5rem;
    text-align: left;
  }
`

const CurrentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-wrap: break-word;

  > span {
    background-color: #0055c6;
    border-radius: 0.6rem;
    color: white;
    font-size: 1.5rem;
    height: fit-content;
    max-height: 2.2rem;
    margin: 0.2rem;
    padding: 0.1rem;
  }
`

const DetailContainer = styled.div`
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  overflow-wrap: anywhere;
`

const AssignmentDetail = styled.span`
  flex-direction: row;
  background-color: #0055c6;
  border-radius: 0.6rem;
  color: white;
  height: fit-content;
  max-height: 2.2rem;
  margin: 0.2rem;
  padding: 0.1rem 0.6rem;
  white-space: nowrap;
  width: fit-content;
`

const TimeSpan = styled.span`
  background-color: #f4ac00;
  border-radius: 0.6rem;
  color: black;
  flex-direction: row;
  font-size: 0.9rem;
  padding: 0.1rem 0.4rem;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
`

const ReadMoreLess = styled.div`
  display: flex;
  justify-content: center;

  a {
    color: #0055c6;
    background: lightgrey;
    border-radius: 0.6rem;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    padding: 0.2rem 5rem;
    text-decoration: none;
  }

  a:link {
    background: lightgrey;
  }

  a:visited {
    background: lightgrey;
  }

  a:hover {
    background: salmon;
  }

  a:active {
    background: salmon;
  }
`

/**
 * Assignment that is continuously queried via pollInterval
 * @public
 * @return {React.ReactElement} - React Assignment component
 */
const Assignment = ({ incident }) => {
  const { assignment } = incident

  const [hidden, setHidden] = useState(true)
  // For styling-debugging purposes
  // data.incident.assignment[0].assignment = 'DC, T1, U4, E1, E2, E4, E5, E6, E7, E8, FM1, FM3'

  // Make an assignment array
  let assignData = assignment[0].assignment.split(',')
  let trimmed = assignData.map((el) => el.trim())
  // let spaced = assignData.map(el => el.trim())

  return (
    <Wrapper>
      {hidden ? (
        <CurrentContainer>
          {trimmed.map((el) => (
            <span key={el}>&nbsp;{el}&nbsp;</span>
          ))}
        </CurrentContainer>
      ) : (
        <DetailContainer>
          <ul>
            {assignment.map((assmnt) => (
              <>
                {assmnt.assignment ? (
                  <li key={assmnt.inc_assignment_id}>
                    <TimeSpan>🕙 {format(new Date(assmnt.created_at), 'HH:mm')}</TimeSpan>
                    {assmnt.assignment
                      .toUpperCase()
                      .split(',')
                      .map((el) => {
                        return <AssignmentDetail key={el}>{el.trim()}</AssignmentDetail>
                      })}
                  </li>
                ) : (
                  ''
                )}
              </>
            ))}
          </ul>
        </DetailContainer>
      )}

      {assignment.length > 1 ? (
        <ReadMoreLess>
          {hidden ? (
            <a href="#" onClick={() => setHidden(false)}>
              &#9660; read more{' '}
            </a>
          ) : (
            <a href="#" onClick={() => setHidden(true)}>
              &#9650; read less{' '}
            </a>
          )}
        </ReadMoreLess>
      ) : (
        <div />
      )}
    </Wrapper>
  )
}

Assignment.displayName = 'Assignment'

Assignment.propTypes = {
  incident: PropTypes.object
}

export default Assignment
