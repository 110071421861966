//@ts-nocheck

/**
 * src/containers/Maps/MapBox2D.js
 *
 * @module MapBox2D
 */

import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@googlemaps/js-api-loader'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import { getDistanceKmFromLatLon } from '../../utilities/Mapping'
import {
  APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS,
  APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS
} from '../../queries/appliances'
import { INCIDENT_FETCH_APPLIANCES } from '../../queries/incidents'
import { Error } from '../../components'
import {
  buildGeoFence,
  didEnterFence,
  getSunsetSunrise,
  makeGeoJson,
  retry
} from '../../utilities'

import fireHydrantIcon from '../../images/hydrant-small.png'
import otherWaterIcon from '../../images/water-drop-small.png'
// const fireHydrantIcon = `https://d76b2d0qyue8q.cloudfront.net/hydrants/json/hydrant-small.png`
// const fireHydrantIcon = `https://s3.us-east-1.amazonaws.com/io.firetek.assets/hydrants/json/hydrant-small.png`
// const otherWaterIcon = `https://s3.us-east-1.amazonaws.com/io.firetek.assets/hydrants/json/water-drop-small.png`
// const otherWaterIcon = `https://d76b2d0qyue8q.cloudfront.net/hydrants/json/water-drop-small.png`

const DEBUG = process.env.DEBUG
const DIRECTIONS_API_VERSION = 'v5'
const GOOGLE_MAP_API = process.env.GOOGLE_MAP_API
const NODE_ENV = process.env.NODE_ENV
const RESPONDER_LIMIT_IN_KM = 30
const HYDRANT_RADIUS_IN_FT = 1200

const MAPBOX_ACCESS_TOKEN_LOCAL = process.env.MAPBOX_ACCESS_TOKEN_LOCAL
let MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN
if (DEBUG) {
  MAPBOX_ACCESS_TOKEN = MAPBOX_ACCESS_TOKEN_LOCAL
}

// This profile can be set to:
// driving, walking, cycling, driving-traffic
// Whatever is set here must be set to the same in:
// dbpub:/utils/getAppliances.js
const MAPBOX_DIRECTIONS_PROFILE = 'driving'

let mapCount = 0

import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
mapboxgl.workerCount = 2

const StreetView = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ '../Maps/StreetView'))
)

const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

// Google Map loader and API key
// let loader
const additionalOptions = {}

// if (!loader) {
//   loader = new Loader({
//     apiKey: GOOGLE_MAP_API,
//     version: 'weekly',
//     ...additionalOptions
//   })
// }

const loader = new Loader({
  apiKey: GOOGLE_MAP_API,
  version: 'weekly',
  ...additionalOptions
})

if (NODE_ENV !== 'production') {
  log.debug('NODE_ENV at MapBox2D: ', NODE_ENV)
  log.debug('DEBUG at MapBox2D: ', DEBUG)
}

const { sunset, sunrise, now } = getSunsetSunrise()
let mapboxStyle
let routeLineColor

if (sunset > now && now > sunrise) {
  // i.e. 6 am until 6 pm
  // mapboxStyle = 'mapbox://styles/mapbox/streets-v11'
  mapboxStyle = 'mapbox://styles/firetek/ckj57hcv56wby19l4p58vm3fn'
  routeLineColor = '#3887be'
} else if (sunset < now && now > sunrise) {
  // i.e. 6 pm until midnight
  // mapboxStyle = 'mapbox://styles/mapbox/navigation-preview-night-v4'
  mapboxStyle = 'mapbox://styles/firetek/ckj5jhgrd2zyp19p5t8j6uje0'
  routeLineColor = 'yellow'
} else if (sunset > now && now < sunrise) {
  // i.e. midnight until 6 am
  // mapboxStyle = 'mapbox://styles/mapbox/navigation-preview-night-v4'
  mapboxStyle = 'mapbox://styles/firetek/ckj5jhgrd2zyp19p5t8j6uje0'
  routeLineColor = 'yellow'
} else {
  // mapboxStyle = 'mapbox://styles/mapbox/streets-v11'
  mapboxStyle = 'mapbox://styles/firetek/ckj57hcv56wby19l4p58vm3fn'
  routeLineColor = '#3887be'
}

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  margin: 0 0.2rem;
`

const ErrorContainer = styled.div`
  background: lightgrey;
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 1rem;
`

const NavigateButton = styled.div`
  align-items: center;
  background: var(--fire-engine-blue);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  display: flex;
  margin: 0.5%;
  padding: 0.3rem 0.5rem;
  position: inherit;
  text-align: center;
  z-index: 4;
  &:link {
    background: var(--fire-engine-blue);
  }
  &:visited {
    background: green;
  }
  &:hover {
    background: rgba(217, 23, 14, 1);
  }
  &:active {
    background: rgba(217, 23, 14, 1);
  }
  a {
    color: white;
    text-decoration: none;
  }
`

const StreetViewButton = styled(NavigateButton)`
  ${({ status }) => {
    if (status) {
      return `display: ${status};`
    }
  }}
  ${({ bgColorSVButton }) => {
    if (bgColorSVButton) {
      return `background: ${bgColorSVButton};`
    }
  }}
`

const RouteButton = styled(NavigateButton)`
  ${({ bgColorRouteButton }) => {
    if (bgColorRouteButton) {
      return `background: ${bgColorRouteButton};`
    }
  }}
`

const WaterButton = styled(NavigateButton)`
  ${({ bgColorWaterButton }) => {
    if (bgColorWaterButton) {
      return `background: ${bgColorWaterButton};`
    }
  }}
`

const HydrantRadiusButton = styled(NavigateButton)`
  background: rgba(217, 23, 14, 0.85);
  font-size: 0.7rem;
  font-weight: normal;
  height: 1.3rem;
  padding: 0.2rem 0.5rem;
  width: max-content;
`

const RouteDistanceButton = styled(HydrantRadiusButton)`
  font-size: 1.4rem;
  height: 2rem;
`

// touch-action: none;
// add this to MapBoxDiv if you want to disable scrolling within the Map
const MapBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vw;

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 100vw;
  }

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    height: 100vw;
  }
`

const MapBox2D = ({ inc, userLocation }) => {
  const {
    department: { dept_id }
  } = inc
  const { inc_id } = inc

  let dLng = inc.longitude
  let dLat = inc.latitude
  let dispatchDestination
  let map
  let usrLat
  let usrLng

  /*******************************/
  // Get closest wet hydrant from dbpub and DB.
  // This represents actual hoselay as derived from Mapbox Matrix.
  const { error: errorWaterSources, data } = useQuery(
    INCIDENT_FETCH_APPLIANCES,
    {
      variables: { inc_id: parseInt(inc_id) },
      fetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )

  if (errorWaterSources)
    return <Error error={`${errorWaterSources}: inc_id = ${inc_id}`} />

  if (data) {
    const { track_closest_appliances_for_incident: waterSources } = data
    // Unfreeze the objects in the waterSources array
    const unfrozenWaterSources = JSON.parse(JSON.stringify(waterSources))
    // Sort the objects by dist_in_feet (which was derived from Mapbox matrix)
    const sortedWaterSources = unfrozenWaterSources.sort((a, b) => {
      return parseFloat(a.dist_in_feet) - parseFloat(b.dist_in_feet)
    })
    log.debug('🐰🐰 sortedWaterSources', sortedWaterSources)
  }
  /*******************************/

  /*******************************/
  // Get all the wet hydrants
  let wetHydrants
  let type = 'wet'
  const { error: errorAppliances, data: dataAppliances } = useQuery(
    APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS,
    {
      variables: {
        dept_id: parseInt(dept_id),
        type: type,
        gps: { lat: parseFloat(dLat), lon: parseFloat(dLng) },
        fenceRadius: 1
      },
      fetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )
  if (dataAppliances) {
    const { appliancesByDeptIdAndTypeAndGPS } = dataAppliances
    wetHydrants = appliancesByDeptIdAndTypeAndGPS
    log.debug('wetHydrants: ', wetHydrants)
  }
  if (errorAppliances)
    return <Error error={`${errorAppliances}: dept_id = ${dept_id}`} />
  /*******************************/

  /*******************************/
  // Get all the other water source types (not wet)
  let otherWater
  // let type = 'wet'
  const { error: errorOtherWater, data: dataOtherWater } = useQuery(
    APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS,
    {
      variables: {
        dept_id: parseInt(dept_id),
        gps: { lat: parseFloat(dLat), lon: parseFloat(dLng) },
        fenceRadius: 3
      },
      fetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )
  if (dataOtherWater) {
    const { appliancesDryPlusByDeptIdAndGPS } = dataOtherWater
    otherWater = appliancesDryPlusByDeptIdAndGPS
    log.debug('otherWater: ', otherWater)
  }
  if (errorOtherWater)
    return <Error error={`${errorOtherWater}: dept_id = ${dept_id}`} />
  /*******************************/

  const mapContainerRef = useRef(null)
  const [isError, setIsError] = useState(false)
  const [routeDistance, setRouteDistance] = useState(0)
  const [toggleRoute, setToggleRoute] = useState(false)
  const [toggleWater, setToggleWater] = useState(false)
  const [toggleSVMap, setToggleSVMap] = useState(false)
  const [SVButtonDisplay, setSVButtonDisplay] = useState('none')
  const [bgColorSVButton, setBgColorSVButton] = useState(
    'var(--fire-engine-blue)'
  )
  const [bgColorRouteButton, setBgColorRouteButton] = useState(
    'var(--fire-engine-blue)'
  )
  const [bgColorWaterButton, setBgColorWaterButton] = useState(
    'var(--fire-engine-blue)'
  )

  const errorMap = (err) => {
    log.error('ERROR: ' + err)
    setIsError(true)
  }

  // Check, using Google StreetViewService, whether there is a street view.
  async function checkIfStreetView() {
    await loader
      .load()
      .then(() => {
        dispatchDestination = new window.google.maps.LatLng(dLat, dLng)
        // Instantiate street view service to test if there is a street view
        let streetViewService = new window.google.maps.StreetViewService()
        streetViewService.getPanorama(
          {
            location: dispatchDestination,
            preference: 'best',
            radius: 50,
            source: 'outdoor'
          },
          (status) => {
            log.debug('STREET VIEW 🐸 status: ', status)
            if (status) {
              // If there is a street view then we want to see the button,
              // so set display:block
              setSVButtonDisplay('block')
            }
          }
        )
      })
      .catch((err) => {
        errorMap(err)
      })
  }

  if (userLocation) {
    usrLat = userLocation.latitude
    usrLng = userLocation.longitude
    // usrLat = Math.round(userLocation.latitude * 10000) / 10000
    // usrLng = Math.round(userLocation.longitude * 10000) / 10000
  } else {
    log.warn('WARNING: no GPS provided to userLocation variable')
  }

  // Calculate how far the responder is from the scene so we can decide
  // which zoom level to use when we display the map.
  const inResponderProximity = (lat1, lon1, lat2, lon2, distanceLimit = 30) => {
    let actualDistance = getDistanceKmFromLatLon(lat1, lon1, lat2, lon2)
    log.debug('distanceLimit: ', distanceLimit)
    log.debug('actualDistance: ', actualDistance)
    log.debug(
      'distanceLimit >= actualDistance',
      distanceLimit >= actualDistance
    )
    return distanceLimit >= actualDistance
  }

  // Creates a circle around a point with a given radius
  const createGeoJSONCircle = function (center, radiusInKm, points) {
    if (!points) points = 64
    let coords = {
      latitude: parseFloat(center[1]),
      longitude: parseFloat(center[0])
    }
    let ret = []
    let distanceX =
      radiusInKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
    let distanceY = radiusInKm / 110.574
    let theta, x, y
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI)
      x = distanceX * Math.cos(theta)
      y = distanceY * Math.sin(theta)

      ret.push([coords.longitude + x, coords.latitude + y])
    }
    ret.push(ret[0])
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [ret]
        }
      }
    }
  }

  const buildMap = async () => {
    await checkIfStreetView()
    // Set zoom based on how close the responder is (RESPONDER_LIMIT_IN_KM).
    // Zoom in more if responder is in the area.
    let zoomFactor =
      usrLng &&
      usrLat &&
      inResponderProximity(usrLat, usrLng, dLat, dLng, RESPONDER_LIMIT_IN_KM)
        ? 13
        : 15
    try {
      // Create the map
      map = await new mapboxgl.Map({
        attributionControl: false,
        container: mapContainerRef.current,
        style: mapboxStyle,
        // style: 'mapbox://styles/mapbox/streets-v11',
        // style: 'mapbox://styles/mapbox/navigation-preview-night-v4',
        // style: 'mapbox://styles/mapbox/navigation-preview-day-v4',
        center: [dLng, dLat],
        pitch: 0,
        zoom: zoomFactor,
        scrollZoom: false,
        boxZoom: false,
        dragPan: false
      })
    } catch (err) {
      errorMap(err)
    }
    mapCount = mapCount + 1
    log.info('🎶 mapCount: ', mapCount)

    // A function to paint on screen a directions request
    // params: coordinates
    function paintRoute(coords) {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: coords.geometry.coordinates
            }
          }
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': routeLineColor,
          'line-width': 5,
          'line-opacity': 0.75
        }
      })
    }

    // Create a function to make a directions request
    function getRoute(startLng, startLat, endLng, endLat) {
      // Make a directions request using driving profile
      log.debug('🚀 Making a getRoute request ...')
      let url = `https://api.mapbox.com/directions/${DIRECTIONS_API_VERSION}/mapbox/${MAPBOX_DIRECTIONS_PROFILE}/${startLng},${startLat};${endLng},${endLat}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken} `
      // Make an XHR request to MapBox API to get the route
      let req = new XMLHttpRequest()
      req.open('GET', url, true)
      req.onload = function () {
        let json = JSON.parse(req.response)
        let distanceInFeet = json.routes[0].distance * 3.28084
        log.debug('🐾 distanceInFeet: ', distanceInFeet)
        // var data = json.routes[0]
        let geojsonRoute = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: json.routes[0].geometry.coordinates
          }
        }

        paintRoute(geojsonRoute)

        // If we're routing to a water source, then use a red line
        // and set the distance to the water in feet
        if (toggleWater) {
          routeLineColor = '#FF0000'
          setRouteDistance(distanceInFeet)
        } else {
          setRouteDistance(0)
        }

        // map.addLayer({
        //   id: 'route',
        //   type: 'line',
        //   source: {
        //     type: 'geojson',
        //     data: {
        //       type: 'Feature',
        //       properties: {},
        //       geometry: {
        //         type: 'LineString',
        //         coordinates: geojsonRoute.geometry.coordinates
        //       }
        //     }
        //   },
        //   layout: {
        //     'line-join': 'round',
        //     'line-cap': 'round'
        //   },
        //   paint: {
        //     'line-color': routeLineColor,
        //     'line-width': 5,
        //     'line-opacity': 0.75
        //   }
        // })
      }
      req.send()
    } // end getRoute()

    if (map) {
      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl())

      // Add marker for destination
      new mapboxgl.Marker({ color: '#f00' }).setLngLat([dLng, dLat]).addTo(map)
      // Add marker for the user if we know his/her location
      if (usrLng && usrLat) {
        new mapboxgl.Marker().setLngLat([usrLng, usrLat]).addTo(map)
      }

      // On mobile devices we need two fingers to pan
      // if (
      // /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      // ) {
      map.dragPan.disable()
      map.scrollZoom.disable()
      map.touchPitch.disable()
      map.on('touchstart', function (e) {
        var oe = e.originalEvent
        if (oe && 'touches' in oe) {
          if (oe.touches.length > 1) {
            oe.stopImmediatePropagation()
            map.dragPan.enable()
          } else {
            map.dragPan.disable()
          }
        }
      })
      // }

      map.on('load', function () {
        // Add route starting point (aka user position) to the map
        if (
          usrLng &&
          usrLat &&
          inResponderProximity(
            usrLat,
            usrLng,
            dLat,
            dLng,
            RESPONDER_LIMIT_IN_KM
          )
        ) {
          map.addLayer({
            id: 'start',
            type: 'circle',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: [usrLng, usrLat]
                    }
                  }
                ]
              }
            },
            paint: {
              'circle-radius': 14,
              'circle-stroke-width': 4,
              'circle-opacity': 0,
              'circle-stroke-color': '#3887be'
            }
          })
        }

        // Add route ending point to the map
        // if (map.getLayer('end')) {
        // map.getSource('end').setData(end)
        // } else {
        if (dLat && dLng) {
          map.addSource(
            'hydrantCircle',
            createGeoJSONCircle(
              [dLng, dLat],
              (HYDRANT_RADIUS_IN_FT * 0.3048) / 1000
            )
          )
          map.addLayer({
            id: 'end',
            type: 'circle',
            source: {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: [dLng, dLat]
                    }
                  }
                ]
              }
            },
            paint: {
              'circle-radius': 14,
              'circle-stroke-color': '#f00',
              'circle-stroke-width': 4,
              'circle-opacity': 0
            }
          })
          map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'hydrantCircle',
            layout: {},
            paint: {
              'line-color': '#f4ac00',
              'line-width': 2
            }
          })
        }

        if (toggleRoute) {
          // Turn off water routing just in case it was turned on before
          setToggleWater(false)
          setBgColorWaterButton('var(--fire-engine-blue)')
          getRoute(usrLng, usrLat, dLng, dLat)
          // No need to show the distance between user and scene
          map.fitBounds(
            [
              [usrLng, usrLat],
              [dLng, dLat]
            ],
            { padding: 75, linear: true }
          )
        }

        // add wet hydrant layer
        if (wetHydrants && wetHydrants.length > 0) {
          // Build hydrant zone with 6 sides
          // Filter the hydrants and only get the ones within the fence,
          // which is specified in miles
          let filteredWetHydrants = wetHydrants.filter((util) => {
            return didEnterFence(
              [util.latitude, util.longitude],
              buildGeoFence(6, dLat, dLng, 0.25)
            )
          })
          // If we don't have at least 2 hydrants, then widen the fence to
          // three-quarters of a mile
          if (filteredWetHydrants.length < 2) {
            log.debug('filteredWetHydrantsQuarterMile: ', filteredWetHydrants)
            filteredWetHydrants = wetHydrants.filter((util) => {
              return didEnterFence(
                [util.latitude, util.longitude],
                buildGeoFence(6, dLat, dLng, 0.75)
              )
            })
          }
          // If we still don't have at least 2 hydrants, then widen the
          // fence to a mile and a quarter
          if (filteredWetHydrants.length < 2) {
            log.debug('filteredWetHydrantsHalfMile: ', filteredWetHydrants)
            filteredWetHydrants = wetHydrants.filter((util) => {
              return didEnterFence(
                [util.latitude, util.longitude],
                buildGeoFence(6, dLat, dLng, 1.25)
              )
            })
          }
          log.debug('final filteredWetHydrants: ', filteredWetHydrants)

          // Put the data into proper geoJson form for MapBox
          let wetHydrantsInGeoJson = makeGeoJson(filteredWetHydrants)

          if (toggleWater) {
            // Turn off user routing just in case it was turned on before
            setToggleRoute(false)
            setBgColorRouteButton('var(--fire-engine-blue)')
            // Reset route distance just in case it was set earlier
            // It will get set appropriately in getRoute()
            routeDistance > 0 ? setRouteDistance(0) : null
            getRoute(
              parseFloat(sortedWaterSources[0].appl_id.longitude),
              parseFloat(sortedWaterSources[0].appl_id.latitude),
              dLng,
              dLat
            )
            map.fitBounds(
              [
                [
                  parseFloat(sortedWaterSources[0].appl_id.longitude),
                  parseFloat(sortedWaterSources[0].appl_id.latitude)
                ],
                [dLng, dLat]
              ],
              { padding: 125, linear: true }
            )
          }

          map.loadImage(fireHydrantIcon, function (error, image) {
            if (error) throw error
            map.addImage('firehydrant-marker', image)

            // Add a source
            map.addSource('points', {
              type: 'geojson',
              data: wetHydrantsInGeoJson,
              cluster: true,
              clusterMaxZoom: 1
            })

            // Add a symbol layer
            map.addLayer({
              id: 'points',
              type: 'symbol',
              source: 'points',
              layout: {
                'icon-image': 'firehydrant-marker',
                // get the location name from the source's "location" property
                'text-field': ['get', 'location'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-anchor': 'top'
              },
              paint: {
                'text-color': '#333'
              }
            })
          })
          // map.resize()
        }

        // add other water layer
        if (otherWater && otherWater.length > 0) {
          // Build hydrant zone with 6 sides
          // Filter the hydrants and only get the ones within the fence,
          // which is specified in miles

          let filteredOtherWater = otherWater.filter((util) => {
            return didEnterFence(
              [util.latitude, util.longitude],
              buildGeoFence(6, dLat, dLng, 0.25)
            )
          })

          // If we don't have at least 2 hydrants, then widen the fence to
          // a half mile
          if (filteredOtherWater.length < 2) {
            log.debug('filteredOtherWaterQuarterMile: ', filteredOtherWater)
            filteredOtherWater = otherWater.filter((util) => {
              return didEnterFence(
                [util.latitude, util.longitude],
                buildGeoFence(6, dLat, dLng, 0.5)
              )
            })
          }

          // If we still don't have at least 2 hydrants, then widen the
          // fence to a full mile
          if (filteredOtherWater.length < 2) {
            log.debug('filteredOtherWaterHalfMile: ', filteredOtherWater)
            filteredOtherWater = otherWater.filter((util) => {
              return didEnterFence(
                [util.latitude, util.longitude],
                buildGeoFence(6, dLat, dLng, 1)
              )
            })
          }

          log.debug('final filteredOtherWater: ', filteredOtherWater)
          // Put the data into proper geoJson form for MapBox
          let otherWaterInGeoJson = makeGeoJson(filteredOtherWater)

          map.loadImage(otherWaterIcon, function (error, image) {
            if (error) throw error
            map.addImage('other-water-marker', image)

            // Add a source
            map.addSource('otherWaterPoints', {
              type: 'geojson',
              data: otherWaterInGeoJson,
              cluster: true,
              clusterMaxZoom: 1
            })

            // Add a symbol layer
            map.addLayer({
              id: 'otherWaterPoints',
              type: 'symbol',
              source: 'otherWaterPoints',
              layout: {
                'icon-image': 'other-water-marker',
                // get the location name from the source's "location" property
                'text-field': ['get', 'location'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-anchor': 'top'
              },
              paint: {
                'text-color': '#333'
              }
            })
          })
          // map.resize()
        }

        // Resize the map after the map container is shown.
        // This prevents the problem of just the upper left tile from being
        // the only tile displayed.
        // setTimeout(() => map.resize(), 500)
      })
    }
    log.debug('🐭🐭 mapbox map: ', map)
    if (map) {
      log.debug('Executing map.remove() ...')
      return () => map.remove()
    }
  }

  useEffect(() => {
    buildMap()
  }, [
    // dLat,
    // dLng
    toggleWater,
    toggleRoute,
    // SVButtonDisplay
    wetHydrants
    // otherWater
  ])

  if (isError) return ' '
  if (!dLat || !dLng) return null

  log.debug('🚾 wetHydrants: ', wetHydrants)
  // <MapBoxContainer data-testid="mapbox2d-MapBoxContainer">
  // </MapBoxContainer>
  return (
    <Fragment>
      <MapBoxDiv className="maps" id="mapbox2d" ref={mapContainerRef}>
        {usrLat && usrLng && dLat && dLng && (
          <Fragment>
            {routeDistance > 0 ? (
              <RouteDistanceButton>
                Road distance to water {parseInt(routeDistance)}'
              </RouteDistanceButton>
            ) : (
              ''
            )}
            {routeDistance > 0 ? (
              ''
            ) : (
              <HydrantRadiusButton>
                hydrant radius {HYDRANT_RADIUS_IN_FT}'
              </HydrantRadiusButton>
            )}
          </Fragment>
        )}
      </MapBoxDiv>
      <StreetView
        id="mapSV"
        inc={inc}
        toggleSVMap={toggleSVMap}
        loader={loader}
      />
      {usrLat && usrLng && dLat && dLng && (
        <Buttons>
          <Fragment>
            <RouteButton bgColorRouteButton={bgColorRouteButton}>
              {toggleRoute ? (
                <a
                  onClick={() => {
                    setToggleRoute(!toggleRoute)
                  }}
                  onPointerUp={() => {
                    setBgColorRouteButton('var(--fire-engine-blue)')
                  }}
                >
                  Hide Route
                </a>
              ) : (
                <a
                  onClick={() => {
                    setToggleRoute(!toggleRoute)
                  }}
                  onPointerUp={() => {
                    setBgColorRouteButton('green')
                  }}
                >
                  Show Route
                </a>
              )}
            </RouteButton>
            <StreetViewButton
              status={SVButtonDisplay}
              bgColorSVButton={bgColorSVButton}
            >
              {toggleSVMap ? (
                <a
                  href="#mapSV"
                  onClick={() => {
                    setToggleSVMap(!toggleSVMap)
                  }}
                  onPointerUp={() => {
                    setBgColorSVButton('var(--fire-engine-blue)')
                  }}
                >
                  Street View Off
                </a>
              ) : (
                <a
                  href="#mapSV"
                  onClick={() => {
                    setToggleSVMap(!toggleSVMap)
                  }}
                  onPointerUp={() => {
                    setBgColorSVButton('green')
                  }}
                >
                  Street View
                </a>
              )}
            </StreetViewButton>
            {wetHydrants && wetHydrants.length > 0 && (
              <WaterButton bgColorWaterButton={bgColorWaterButton}>
                {toggleWater ? (
                  <a
                    onClick={() => {
                      setToggleWater(!toggleWater)
                      setRouteDistance(0)
                    }}
                    onPointerUp={() => {
                      setBgColorWaterButton('var(--fire-engine-blue)')
                    }}
                  >
                    Stop Water
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setToggleWater(!toggleWater)
                    }}
                    onPointerUp={() => {
                      setBgColorWaterButton('green')
                    }}
                  >
                    Route water
                  </a>
                )}
              </WaterButton>
            )}
            <NavigateButton>
              <a
                href={`https://www.google.com/maps/dir/?api=1&origin=${usrLat},${usrLng}&destination=${dLat},${dLng}&travelmode=driving`}
              >
                Google Nav
              </a>
            </NavigateButton>
          </Fragment>
        </Buttons>
      )}
      {isError && (
        <ErrorContainer>Mapping encountered an error.</ErrorContainer>
      )}
    </Fragment>
  )
}

MapBox2D.propTypes = {
  inc: PropTypes.any,
  userLocation: PropTypes.object
}

export default MapBox2D
