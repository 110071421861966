//@ts-nocheck

/**
 * src/themes/theme.js
 *
 */

import theme from 'styled-theming'

export const backgroundColor = theme('mode', {
  light: 'var(--light-bg)',
  dark: 'var(--dark-bg)'
})

export const textColor = theme('mode', {
  light: 'var(--light-fg)',
  dark: 'var(--dark-fg)'
})

export const buttonBackgroundColor = theme('mode', {
  light: 'var(--fire-engine-blue)',
  dark: 'var(--wizard-grey)'
})

export const cardTextColor = theme('mode', {
  light: 'white',
  dark: 'white'
})

export const cardBorderColor = theme('mode', {
  light: '#1d1d1d',
  dark: '#bcbcbc'
})

export const cardBackgroundColor = theme('mode', {
  light: 'var(--tertiary-color)',
  dark: 'var(--tertiary-color)'
})

export const buttonTextColor = theme('mode', {
  light: 'white',
  dark: 'white'
})

export const linkTextColor = theme('mode', {
  light: 'var(--primary-complement-color)',
  dark: 'var(--secondary-complement-color)'
})

export const linkHoverTextColor = theme('mode', {
  light: 'var(--fire-engine-red)',
  dark: 'var(--fire-engine-red)'
})

export const fontFamily = theme('mode', {
  light: 'Roboto, Arial, Trebuchet MS, sans-serif',
  dark: 'Roboto, Arial, Trebuchet MS, sans-serif'
})

export const sectionDivider = theme('mode', {
  light: 'firebrick',
  dark: '#847f7f'
})

export const sectionHeading = theme('mode', {
  light: 'firebrick',
  dark: '#f6e58d'
})
