//@ts-nocheck

/**
 * src/utilities/Breakpoints.js
 *
 * Usage:
 *
  import { mediaAbove } from '../../utilities'

  ${mediaAbove.desktop`
    min-width: 850px;
    `}

    ... meaning that for a device width that is greater than desktop (i.e. 992px), then the min-width setting of 850px will be used.

 * https://www.styled-components.com/docs/advanced#media-templates
 */

import { css } from 'styled-components'

const size = {
  desktop: 992,
  tablet: 768,
  phone: 576
}

export const mediaAbove = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const mediaBelow = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})
