//@ts-nocheck

/**
 * src/containers/Incident/NoPerms.js
 *
 * This is the page we show when the user tries to view an incident he/she
 * doesn't have permission to see
 */

import React, { Fragment } from 'react'
import styled from 'styled-components'

import { backgroundColor, textColor } from '../../themes/theme'
import Header from '../../components/Header'

const Wrapper = styled.div`
  background-color: ${backgroundColor};
  border-radius: 0.6rem;
  color: ${textColor};
  display: block;
  min-height: 75vh;
  padding: 0.3rem 0;
  margin: 0.3rem;
  text-align: center;

  img {
    height: 5rem;
  }

  p {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.7rem;
    letter-spacing: 0.4rem;
  }
`

export default function NoPerms() {
  return (
    <Fragment>
      <Header />
      <Wrapper>
        <h2>Oops! &nbsp;Sorry ...</h2>
        <img
          src="https://d76b2d0qyue8q.cloudfront.net/media/exploding-head.svg"
          alt="Emoji: exploding head"
        />
        <p>You are not allowed to view this incident</p>
      </Wrapper>
    </Fragment>
  )
}
