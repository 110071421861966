/**
 * src/utilities/WakeLock.js
 *
 * wakeLock API call - only useful for Android, not iOs
 *
 * default timeout is 10 minutes
 *
 */

const DEBUG = process.env.DEBUG

const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

export const runWakeLock = (wakelock_timeout = 1000 * 60 * 10) => {
  if ('wakeLock' in navigator) {
    let wakeLock = null
    log.debug('Screen Wake Lock API supported 🎉')

    // Function that attempts to request a screen wake lock.
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request('screen')
        wakeLock.addEventListener('release', (e) => {
          log.debug('Screen Wake Lock released:; ', e)
        })
        log.debug('Screen Wake Lock is active')
        log.debug('wakeLock: ', wakeLock)
      } catch (err) {
        log.error(`${err.name}, ${err.message}`)
      }
    }

    // Request a screen wake lock ...
    requestWakeLock()
    // ... and release it again after many milliseconds.
    window.setTimeout(() => {
      wakeLock.release()
      wakeLock = null
    }, wakelock_timeout)

    // Reacquire the screen wake lock after leaving and coming back to this page
    const handleVisibilityChange = async () => {
      if (wakeLock !== null && document.visibilityState === 'visible') {
        await requestWakeLock()
      }
    }

    // Listen for a visibilitychange event
    document.addEventListener('visibilitychange', handleVisibilityChange)
    // end wakeLock
  } else {
    log.debug('Screen Wake Lock API not supported 😩')
  }
}
