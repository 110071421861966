/**
 * src/components/LoadingSuspense.js
 *
 * @module LoadingSuspense
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { sectionHeading } from '../themes/theme'

const Bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0.8);
  } 40% {
    transform: scale(1.0);
  }
`

const Card = styled.div`
  border: thin solid ${sectionHeading};
  border-radius: 0.5rem;
  box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 1);
  color: ${sectionHeading};
  font-size: 1.17rem;
  margin: 0.3rem;
  min-width: 77%;
  min-height: ${(props) => props.minHeight || '85px'};
  padding: 0.5rem 1rem;
`

// const CardIncType = styled(Card)`
//   background-image: linear-gradient(rgb(255, 166, 0) 0%, rgb(238, 119, 0) 100%);
// `

const Wrapper = styled.div`
  display: block;
  padding: 1rem 7rem;
  img#engine {
    margin: 0 auto;
  }
  animation: ${Bounce} 1s infinite ease-in-out both;
  &:first-child {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`

const LoadingSuspense = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <img
          id="engine"
          src="https://d76b2d0qyue8q.cloudfront.net/media/fire-engine.png"
          alt="fire_engine"
          width="200"
          height="39"
        />
      </Wrapper>
      <Card minHeight={'105px'}>&nbsp;</Card>
      <Card minHeight={'97px'}>Location</Card>
      <Card minHeight={'67px'}>Cross Street</Card>
      <Card minHeight={'115px'}>Assignment</Card>
      <Card minHeight={'145px'}>Remarks</Card>
    </React.Fragment>
  )
}

LoadingSuspense.propTypes = {
  loading: PropTypes.string
}

LoadingSuspense.displayName = 'LoadingSuspense'

export default LoadingSuspense
