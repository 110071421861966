//@ts-nocheck

/**
 * src/containers/Incident/Notifications.js
 *
 */

import React, { Fragment, useContext, useState } from 'react'
import { Error, Loading } from '../../components'
import TOGGLE_SLEEP from '../../mutations/toggleSleep'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { UserContext } from '../App'
import { textColor } from '../../themes/theme'
import { Slider, Switch } from '../../components/Switches'

const DEBUG = false
import * as log from 'loglevel'
log.enableAll()

const SwitchItem = styled.div`
  border: 0 solid var(--box-color);
  margin: 0.3rem 0.1rem 0.3rem 0.2rem;
  padding: 0;

  label {
    padding: 0.5rem 0.1rem 0 0;
  }
`

const NotifyStatus = styled.div`
  list-style: none;
  font-family: 'Arial', sans-serif;
  color: ${textColor};
  padding: 1rem 0.5rem;
`

const UserSettings = ({ ...args }) => {
  const { userByUslug: user } = useContext(UserContext)
  const { user_id, is_sleeping } = user
  const [switchPosition, setSwitchPosition] = useState(is_sleeping)

  if (DEBUG) {
    console.log('args: ', args)
    console.log('user_id: ', user_id)
    console.log('user.uslug: ', user.uslug)
  }

  const [userEdit, { error, loading }] = useMutation(TOGGLE_SLEEP, {
    awaitRefetchQueries: true,
    onCompleted(result) {
      log.debug('userEdit result: ', result)
    }
  })

  if (error) return <Error error={`${error}`} />
  if (loading) return <Loading loading={`${loading}`} />

  const handleClick = () => {
    setSwitchPosition(!switchPosition)
    userEdit({ variables: { user_id, is_sleeping: !switchPosition } })
  }

  return (
    <Fragment>
      <NotifyStatus>
        <SwitchItem>
          <Switch>
            <input
              type="checkbox"
              id="sleep"
              defaultChecked={!switchPosition}
              onClick={() => handleClick()}
            />
            <Slider />
          </Switch>
          <label htmlFor="sleep">
            {' '}
            Notifications {switchPosition ? 'OFF' : 'ON'}
          </label>
        </SwitchItem>
      </NotifyStatus>
    </Fragment>
  )
}

UserSettings.displayName = 'UserSettings'

export default UserSettings
