/**
 * src/containers/Incident/DispatchRecording.js
 *
 * @module DispatchRecording
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DEBUG = Boolean(process.env.DEBUG)
const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

// This button is styled the same as the buttons found in containers/Incident/Menu.js(TopButtons). Make changes to both and should be refactored.
// TODO: refactor
const Button = styled.button`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  background-color: #0055c6;
  border-radius: 0.6rem;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.2em;
  font-family: 'Arial', 'Trebuchet MS', sans-serif;
  padding: 0.6rem;

  &:active {
    background-color: firebrick;
    filter: brightness(120%) saturate(120%);
  }

  &:hover {
    background-color: firebrick;
    filter: brightness(120%) saturate(120%);
  }
`

const PlayButton = styled(Button)`
  background-color: #0055c6;
  padding: 0.6rem;
`
const StopButton = styled(Button)`
  background-color: #d9170e;
  display: none;
  padding: 0.6rem;
`

/**
 * Dispatch recording player
 * @public
 * @return {React.ReactElement} - React Dispatch recording component
 */
const DispatchRecording = ({ incident }) => {
  const { recordings } = incident

  // Create a ref to manage <audio>
  let dispatchAudio = React.createRef()

  const { is_visible, url } = recordings[0]

  const nowPlaying = () => {
    // setPlayStatus('PLAYING')
    log.info('playing')
  }

  const nowPausing = () => {
    // setPlayStatus('STOPPED')
    log.info('pausing')
  }

  const handleOnPlayClick = async () => {
    try {
      await dispatchAudio.current.play()
      playButton.style.display = 'none'
      stopButton.style.display = 'inherit'
      nowPlaying()
    } catch (err) {
      playButton.style.display = 'inherit'
      log.error(`ERROR: audio not started in handleOnPlayClick()`)
    }

    try {
      dispatchAudio.current.onended = function () {
        stopButton.style.display = 'none'
        playButton.style.display = 'inherit'
        dispatchAudio.current.load()
      }
    } catch (err) {
      log.error(`ERROR: audio not ended in handleOnPlayClick()`)
    }
  }

  const handleOnStopClick = async () => {
    try {
      await dispatchAudio.current.pause()
      stopButton.style.display = 'none'
      playButton.style.display = 'inherit'
      nowPausing()
    } catch (err) {
      playButton.style.display = 'inherit'
      log.error(`ERROR: audio not stopped in handleOnStopClick()`)
    }
  }

  // display the Dispatch (aka Play) and Stop buttons
  if (recordings[0]) {
    return (
      <React.Fragment>
        {is_visible && (
          <React.Fragment>
            <audio cors="*" autoPlay={false} src={url} ref={dispatchAudio}>
              Your browser does not support the <code>audio</code> element.
            </audio>
            <PlayButton id="playButton" onClick={() => handleOnPlayClick()}>
              Dispatch
            </PlayButton>
            <StopButton id="stopButton" onClick={() => handleOnStopClick()}>
              Stop
            </StopButton>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  } else {
    return ''
  }
}

DispatchRecording.displayName = 'DispatchRecording'

DispatchRecording.propTypes = {
  incident: PropTypes.object
}

export default DispatchRecording
