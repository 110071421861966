/**
 * src/components/Modals.js
 *
 * Usage:
 *
import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalContent, ModalCloseX } from '../../components'

const SomeComponent = () => {
  const [revealModal, setRevealModal] = useState(false)
  return (
    <React.Fragment>
        <Modal
          flag={revealModal}
          onClick={() => setRevealModal(false)}
        >
          <ModalContent>
          <ModalCloseX onClick={() => setRevealModal(false)}>&times;</ModalCloseX>
          <p>
            modal content goes here
          </p>
          </ModalContent>
        </Modal>

        <OtherContent>
          <button id="modalButton" onClick={() => setRevealModal(true)}>Open Modal</button>
          ...
        <OtherContent>
    </React.Fragment>
  )
}
 *
 */

import styled from 'styled-components'

import { backgroundColor, textColor } from '../themes/theme'

export const Modal = styled.div`
  visibility: hidden; /* Hidden by default */
  ${({ flag }) => {
    if (flag) {
      return `
        visibility: visible;
      `
    }
  }}
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.75s 0s, transform 0.25s;
`

export const ModalContent = styled.div`
  background-color: ${(props) => {
    if (props.background) return props.background
    return backgroundColor
  }};
  border: 2px solid #888;
  border-radius: 0.5rem;
  color: ${textColor};
  margin: 10% auto; /* 10% from the top and centered */
  max-width: fit-content;
  padding: ${(props) => {
    if (props.padding) return props.padding
    return 'inherit'
  }};
  width: 80%; /* Could be more or less, depending on screen size */

  p {
    margin-block-start: 1rem;
    margin-block-end: 0;
    padding: 0.5rem 1rem;
  }
`

export const ModalCloseX = styled.div`
  color: red;
  float: left;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;

  &:hover {
    color: red;
    cursor: pointer;
  }

  &:focus {
    color: red;
    cursor: pointer;
  }
`
