/**
 * src/components/Button.js
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Loader from './Loader'

import { buttonBackgroundColor, buttonTextColor, fontFamily } from '../themes/theme'

const StyledButton = styled.button`
  background-color: ${buttonBackgroundColor};
  color: ${buttonTextColor};
  border: 0 solid ${buttonBackgroundColor};
  border-radius: 0.3rem;
  cursor: pointer;
  font-family: ${fontFamily};
  font-size: ${props => {
    if (props.big) return '20px'
    return '16px'
  }};
  margin: 1rem;
  outline: none;
  padding: 0.7rem 0.9rem;
  text-decoration: none;
  ${props => {
    if (props.secondary) {
      return css`
        background-color: firebrick;
        color: white;
      `
    } else if (props.background) {
      return css`
        background-color: ${props.background};
        color: ${buttonTextColor};
      `
    } else if (props.primary) {
      return css`
        background-color: var(--fire-engine-blue);
        color: white;
      `
    }
  }}
  &:active {
    background-color: var(--wizard-grey);
  }

  &:hover {
    background-color: var(--wizard-grey);
  }
`

const Button = ({ secondary, big, loading, children, ...props }) => {
  return (
    <StyledButton secondary={secondary} big={big} {...props}>
      {loading ? <Loader small white /> : children}
    </StyledButton>
  )
}

Button.propTypes = {
  big: PropTypes.bool,
  children: PropTypes.any,
  loading: PropTypes.bool,
  secondary: PropTypes.bool
}

export default Button
