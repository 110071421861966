/**
 * src/containers/Incident/Remarks.js
 *
 */

import React, { useState } from 'react'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import { cardBorderColor } from '../../themes/theme'
import styled from 'styled-components'

/**
 * Get the difference between two strings.
 * @param {string} a - the first string.
 * @param {string} b - the second string.
 * @public
 * @returns {string} result - the difference between the two strings.
 */
const getDifference = (a, b) => {
  let i = 0
  let j = 0
  let result = ''

  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) result += b[j]
    else i++
    j++
  }
  return result
}

const Wrapper = styled.div`
  display: block;
  justify-content: space-between;
  font-size: 1rem;

  ul {
    list-style-type: none;
    padding: 0 1rem 0 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  li {
    border-radius: 0.6rem;
    border: 1px dotted;
    border-color: ${cardBorderColor};
    line-height: 1.5;
    padding: 0.25rem 0.5rem 0.25rem 0.1rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
`

const CreatedAtTag = styled.span`
  background-color: #f4ac00;
  border-radius: 0.6rem;
  color: black;
  font-size: 0.9rem;
  padding: 0.1rem 0.4rem;
  margin-right: 0.5rem;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
`

const ReadMoreLess = styled.div`
  display: flex;
  justify-content: center;

  a {
    background: lightgrey;
    border-radius: 0.6rem;
    color: #0055c6;
    font-size: 0.9rem;
    margin-top: 0.2rem;
    padding: 0.2rem 5rem;
    text-decoration: none;
  }

  a:link {
    background: lightgrey;
  }

  a:visited {
    background: lightgrey;
  }

  a:hover {
    background: salmon;
  }

  a:active {
    background: salmon;
  }
`

/**
 * Remarks that are continuously queried in Incident component
 * @public
 * @return {React.ReactElement} - React Remarks component
 */
const Remarks = ({ incident }) => {
  const { remarks } = incident
  const [hidden, setHidden] = useState(true)

  console.log(`🌹 remarks: ${JSON.stringify(remarks)}`)

  // let remarksArr = []
  // if (remarks.length >= 1) {
  //   let remarkCount = remarks.length - 1
  //   let k = 0
  //   let diff = ''
  //   while (k <= remarkCount) {
  //     // check for k+1 values before moving on
  //     if (remarks[k + 1]) {
  //       // check for null values before getting string differences
  //       if (remarks[k + 1].remark !== null && remarks[k].remark !== null) {
  //         diff = getDifference(remarks[k + 1].remark, remarks[k].remark)
  //         if (diff !== '') {
  //           {
  //             /* Remove leading , */
  //           }
  //           if (diff.slice(0, 1) === ',' || diff.slice(0, 1) === '.') {
  //             diff = diff.slice(1).trim()
  //           }
  //           remarksArr.push({
  //             remark: diff,
  //             created_at: format(new Date(remarks[k].created_at), 'HH:mm'),
  //             key: remarks[k].inc_remark_id
  //           })
  //         }
  //       }
  //     }
  //     k++
  //   }
  //   /* Push the last entry onto the remarks array */
  //   if (remarks[remarkCount].remark) {
  //     remarksArr.push({
  //       remark: remarks[remarkCount].remark.trim(),
  //       created_at: format(new Date(remarks[remarkCount].created_at), 'HH:mm'),
  //       key: remarks[remarkCount].inc_remark_id
  //     })
  //   }
  //   /* If there's no remark entries so far and there is one entry at index 0,
  //    * then push the entry onto the remarks array */
  //   if (remarksArr.length === 0) {
  //     if (remarks[0].remark) {
  //       remarksArr.push({
  //         remark: remarks[0].remark.trim(),
  //         created_at: format(new Date(remarks[0].created_at), 'HH:mm'),
  //         key: remarks[0].inc_remark_id
  //       })
  //     }
  //   }
  // }
  // /* End build a remarks array */

  return (
    <React.Fragment>
      {/* Begin test for at least 1 remark */}
      {remarks.length > 0 ? (
        <Wrapper>
          {/* If hidden is true, then just show the only remark we have */}
          {hidden ? (
            <ul>
              {remarks[0].created_at && (
                <li>
                  <CreatedAtTag>🕒 {format(new Date(remarks[0].created_at), 'HH:mm')}</CreatedAtTag>
                  {remarks[0].remark}
                </li>
              )}
            </ul>
          ) : (
            <ul>
              {/* If hidden is false, then loop through all remarks */}
              {remarks.map((remark) => (
                <li key={remark.key}>
                  <CreatedAtTag>🕒 {format(new Date(remark.created_at), 'HH:mm')}</CreatedAtTag>
                  {remark.remark}
                </li>
              ))}
            </ul>
          )}

          {/* Begin test for more than 1 remark to see if we should show 'read more' link */}
          {remarks.length > 1 ? (
            <ReadMoreLess>
              {hidden ? (
                <a href="#" onClick={() => setHidden(false)}>
                  &#9660; read more{' '}
                </a>
              ) : (
                <a href="#remarks" onClick={() => setHidden(true)}>
                  &#9650; read less{' '}
                </a>
              )}
            </ReadMoreLess>
          ) : (
            <div />
          )}
          {/* End test for more than 1 remark */}
        </Wrapper>
      ) : (
        <div />
      )}
      {/* End test for at least 1 remark */}
    </React.Fragment>
  )
}

Remarks.displayName = 'Remarks'

Remarks.propTypes = {
  incident: PropTypes.object
}

export default Remarks
