/**
 * src/utilities/Colors.js
 *
 * @module Colors.js
 *
 */

export const purple = '#524763'
export const teal = '#82D8D8'
export const boxColor = '#52473C'

export const social = {
  twitter: '#00aced',
  facebook: '#3b5998'
}
