//@ts-nocheck

/**
 * src/containers/Incident/IncidentList.js
 *
 */

import React, { useContext } from 'react'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'

import { getAlarmColor } from '../../utilities/callTypeColors'
import { cardBorderColor, textColor } from '../../themes/theme'
import { Error, Loading } from '../../components'
import { INCIDENTS_FETCH_BY_DEPT_ID } from '../../queries/incidents'
import { UserContext } from '../App'

const INCIDENT_LIST_COUNT = 60

const Wrapper = styled.div`
  display: block;

  a {
    text-decoration: none;
  }
`

const IncidentCard = styled.div`
  display: grid;
  font-size: 0.95rem;
  grid-template-columns: minmax(4.2rem, 1fr) 4fr;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  border: thin solid ${cardBorderColor};
  margin: 0 1rem 0.5rem 0.8rem;
  padding: 0;
`

const IncID = styled.span`
  color: white;
  grid-area: 1/1/3/2;
  background: var(--box-color);
  padding: 0.2rem 0.5rem;
  text-align: center;
  border-radius: 0.5rem 0 0 0.5rem;
`

const IncCategory = styled.span`
  background-color: ${(props) => props.alarmColor || 'palevioletred'};
  border-radius: 0 0 0.5rem 0;
  color: white;
  grid-area: 2/2/3/3;
  padding: 0.1rem 1.4rem;
  overflow-wrap: anywhere;
  word-break: break-word;
`

const IncLocation = styled.span`
  color: ${textColor};
  border-radius: 0 0.5rem 0 0;
  grid-area: 1/2/2/3;
  padding: 0.2rem 0.8rem;
`

const Logo = styled.div`
  background-color: var(--dark-bg);
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 0.2rem 1rem;
`

const IncidentList = ({ incident }) => {
  let {
    department: { dept_id }
  } = incident

  const { userByUslug: user } = useContext(UserContext)
  const { uslug } = user

  const { loading, error, data } = useQuery(INCIDENTS_FETCH_BY_DEPT_ID, {
    variables: { limit: INCIDENT_LIST_COUNT, dept_id: parseInt(dept_id) },
    fetchPolicy: 'cache-first',
    partialRefetch: true
  })

  if (error) return <Error error={`${error}: dept_id = ${dept_id}`} />
  if (loading) return <Loading loading={`${dept_id}`} />

  // // Determine if user is allowed to see the incident list.
  // // If incident.department.dept_id is included in user.department[i].dept_id,
  // // then accum will be non-zero, therefore show the incident list
  // let accum = 0
  // user.department.forEach(dept => {
  //   if (parseInt(dept.dept_id) === parseInt(dept_id)) {
  //     accum += parseInt(dept.dept_id)
  //   }
  // })

  // if (accum === 0) {
  //   // user is not is the department associated with this incident, so
  //   // let's redirect this user to his/her own first listed department
  //   dept_id = user.department[0].dept_id
  // }

  return (
    <Wrapper>
      <Logo>
        <img
          alt="FireTek"
          src="https://d76b2d0qyue8q.cloudfront.net/media/firetek-banner.png"
          className="logo"
          width="130px"
        />
      </Logo>

      {data.incidentsByDeptId.map((inc) => (
        <Link to={`/s/${inc.slug}/${uslug}`} key={inc.slug}>
          <IncidentCard>
            <IncID>
              {format(new Date(inc.timeout), 'HH:mm')}
              <br />
              {format(new Date(inc.timeout), 'EEE')}
            </IncID>
            <IncCategory alarmColor={getAlarmColor(inc.severity.severity)}>{inc.inc_category.trim()}</IncCategory>
            <IncLocation>{inc.location}</IncLocation>
          </IncidentCard>
        </Link>
      ))}
      <br />
      {/* props.children */}
    </Wrapper>
  )
}

IncidentList.propTypes = {
  children: PropTypes.node,
  incident: PropTypes.object,
  limit: PropTypes.number,
  user_id: PropTypes.string,
  uslug: PropTypes.string
}

export default IncidentList
