//@ts-nocheck

/**
 * src/index.js
 *
 */

import * as React from 'react'
import * as ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import fetch from 'cross-fetch'

import {
  ApolloLink,
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client'

import WebFont from 'webfontloader'

import { App } from './containers/App'

import 'normalize.css'

const DEBUG = process.env.DEBUG
const GQL_AUTH_TOKEN = process.env.GQL_AUTH_TOKEN
const NODE_ENV = process.env.NODE_ENV

import log from 'loglevel'
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

log.info(`NODE_ENV: ${NODE_ENV}, DEBUG: ${DEBUG}`)

let HOSTNAME = location.hostname
let PORT = 4000
let URI = `https://${HOSTNAME}:${PORT}/graphql`

WebFont.load({
  google: {
    families: ['Roboto:300,400,700']
  },
  loading: function () {
    console.log('Google fonts are being loaded')
  },
  active: function () {
    console.log('Google fonts have been rendered')
  }
})


// if (NODE_ENV === 'development') {
//   log.info(`Not loading Sentry because we're in development mode ...`)
// } else {
//   // Load up Sentry error monitoring
//   log.info(`loading Sentry ...`)
//   Sentry.init({
//     dsn:
//       'https://bbc3bdfbe52c455c9066c6c135239bb3@o463929.ingest.sentry.io/5469565',
//     environment: NODE_ENV,
//     integrations: [new Integrations.BrowserTracing()],
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0
//   })
// }

// Do not use https and change path if we're running locally
if (
  location.hostname === 'localhost' ||
  location.hostname === '0.0.0.0' ||
  location.hostname === '127.0.0.1' ||
  location.hostname === 'doodle'
) {
  URI = `http://${location.hostname}:${PORT}/graphql`
}

const cache = new InMemoryCache()

// // // from: https://www.apollographql.com/docs/react/advanced/caching/
// const cache = new InMemoryCache({
//   typePolicies: {
//     Incident: { keyFields: ['inc_id'] },
//     IncidentAssignment: { keyFields: ['inc_assignment_id'] },
//     IncidentRemark: { keyFields: ['inc_remark_id'] },
//     IncidentRecording: { keyFields: ['inc_recording_id'] },
//     ResponsesUsers: { keyFields: ['resp_user_id'] },
//     User: { keyFields: ['user_id'] }
//   }
// })

const authLink = new ApolloLink((operation, forward) => {
  //// Retrieve the authorization token from local storage.
  //// const token = localStorage.getItem('auth_token');
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authentication: GQL_AUTH_TOKEN ? `Bearer ${GQL_AUTH_TOKEN}` : ''
    }
  })
  // Call the next link in the middleware chain.
  return forward(operation)
})

const httpLink = new HttpLink({
  uri: URI, // Server URL (must be absolute)
  fetch,
  credentials: 'same-origin' // Additional fetch() options like `credentials` or `headers`
})

const client = new ApolloClient({
  cache: cache,
  // link: httpLink,
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  credentials: 'include'
})

const Main = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
)

module.hot.accept()

ReactDOM.render(<Main />, document.getElementById('root'))

export default client
