//@ts-nocheck

/**
 * src/queries/appliances.js
 *
 */

import { gql } from '@apollo/client'

const APPLIANCES_FETCH_BY_DEPT = gql`
  query AppliancesByDeptId($dept_id: Int) {
    appliancesByDeptId(dept_id: $dept_id) {
      appl_id
      type
      st_number
      st_name
      st_type
      latitude
      longitude
      department {
        dept_name
      }
    }
  }
`

const APPLIANCES_FETCH_BY_DEPT_AND_TYPE = gql`
  query AppliancesByDeptIdAndType($dept_id: Int, $type: String) {
    appliancesByDeptIdAndType(dept_id: $dept_id, type: $type) {
      appl_id
      type
      st_number
      st_name
      st_type
      latitude
      longitude
      department {
        dept_name
      }
    }
  }
`

const APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS = gql`
  query AppliancesByDeptIdAndTypeAndGPS(
    $dept_id: Int
    $type: String
    $gps: GPS
    $fenceRadius: Float
  ) {
    appliancesByDeptIdAndTypeAndGPS(
      dept_id: $dept_id
      type: $type
      gps: $gps
      fenceRadius: $fenceRadius
    ) {
      appl_id
      type
      st_number
      st_name
      st_type
      latitude
      longitude
      department {
        dept_name
      }
    }
  }
`

const APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS = gql`
  query AppliancesDryPlusByDeptAndGPS(
    $dept_id: Int
    $gps: GPS
    $fenceRadius: Float
  ) {
    appliancesDryPlusByDeptIdAndGPS(
      dept_id: $dept_id
      gps: $gps
      fenceRadius: $fenceRadius
    ) {
      appl_id
      type
      st_number
      st_name
      st_type
      latitude
      longitude
      department {
        dept_name
      }
    }
  }
`

export {
  APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS,
  APPLIANCES_FETCH_BY_DEPT,
  APPLIANCES_FETCH_BY_DEPT_AND_TYPE,
  APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS
}
