/**
 * src/hooks/use-watch-location.js
 *
 * originally from: https://github.com/NorbertB29/geolocation-api-hook/blob/master/src/hooks/useWatchLocation.js
 *
 *
 * Usage:

import { useWatchLocation } from 'hooks/use-watch-location'

// settings is an optional argument
const settings = useMemo(() => {
  return { enableHighAccuracy: true }
}, [])

const { userLocation, cancelLocationWatch, error } = useWatchLocation(settings)

useEffect(() => {
  if (!userLocation) return

  // Cancel location watch after 3 sec once the location was found.
  setTimeout(() => {
    console.log('location useWatchLocation: ', userLocation)
    cancelLocationWatch()
  }, 3000)
}, [userLocation, cancelLocationWatch])

 *
 */

import { useState, useEffect, useRef } from 'react'

export const useWatchLocation = (options = {}) => {
  // store location in state
  const [userLocation, setUserLocation] = useState()
  // store error message in state
  const [error, setError] = useState()
  // save the returned id from the geolocation's `watchPosition` to be able to cancel the watch instance
  const locationWatchId = useRef(null)

  // Success handler for geolocation's `watchPosition` method
  const handleSuccess = (pos) => {
    const { latitude, longitude } = pos.coords

    setUserLocation({
      latitude,
      longitude
    })
  }

  // Error handler for geolocation's `watchPosition` method
  const handleError = (err) => {
    setError(err.message)
  }

  // Clears the watch instance based on the saved watch id
  const cancelLocationWatch = () => {
    const { geolocation } = navigator

    if (locationWatchId.current && geolocation) {
      geolocation.clearWatch(locationWatchId.current)
    }
  }

  useEffect(() => {
    const { geolocation } = navigator

    // If the geolocation is not defined in the used browser we handle it as an error
    if (!geolocation) {
      setError('Geolocation is not supported.')
      return
    }

    // Start to watch the location with the Geolocation API
    locationWatchId.current = geolocation.watchPosition(handleSuccess, handleError, options)

    // Clear the location watch instance when React unmounts the used component
    return cancelLocationWatch
  }, [options])

  return { userLocation, cancelLocationWatch, error }
}
