/**
 * src/components/Links.js
 *
 * Usage:
 *

   {user.is_admin ? <Links to={`/users/delete/${user.user_id}`}>Delete</Links> : <div />}

 *
 */

import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { textColor, linkTextColor, linkHoverTextColor } from '../themes/theme'

const Links = styled(Link)`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  cursor: pointer;
  font-size: 1em;
  color: ${linkTextColor};
  ${(props) => {
    if (props.textWhite) {
      return css`
        color: white;
      `
    } else if (props.textBlack) {
      return css`
        color: black;
      `
    }
  }};

  &:hover {
    color: ${(props) => props.hoverColor || linkHoverTextColor};
  }
`

export default Links
