/**
 * src/components/NotFound.js
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React, { Fragment } from 'react'
import styled from 'styled-components'

import { backgroundColor, textColor } from '../themes/theme'

import { A, Header } from './index'

const Wrapper = styled.div`
  background-color: ${backgroundColor};
  border-radius: 0.6rem;
  color: ${textColor};
  display: block;
  min-height: 75vh;
  padding: 0.3rem 0;
  margin: 0.3rem;
  text-align: center;

  img {
    height: 5rem;
  }

  p {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.7rem;
    letter-spacing: 0.4rem;
  }
`

function NotFound() {
  return (
    <Fragment>
      <Header />
      <Wrapper>
        <h2>Oops! &nbsp;Sorry ...</h2>
        <img
          src="https://d76b2d0qyue8q.cloudfront.net/media/exploding-head.svg"
          alt="Emoji: exploding head"
        />
        <p>The page you seek does not exist</p>
        <p>
          <A color="var(--fire-engine-yellow)" href="/">
            Home
          </A>
        </p>
      </Wrapper>
    </Fragment>
  )
}

NotFound.displayName = 'NotFound'

export default NotFound
