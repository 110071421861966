//@ts-nocheck

/**
 * src/hooks/use-incident-by-slug.js
 *
 * @param {string} slug - the slug for the incident
 * @param {int} poll - the poll interval for the query, defaults to 0 (no polling)
 * @param {boolean} skip - whether to skip making the query or not
 * @returns {object} incident - the most recent incident for the given department
 */

import { useQuery } from '@apollo/client'
import { INCIDENT_FETCH_BY_SLUG } from '../queries/incidents'

const useIncidentBySlug = ({ slug = '', poll = 0, skip = false } = {}) => {
  const {
    loading: loadingSlug,
    error: errorSlug,
    data: incidentBySlug
  } = useQuery(INCIDENT_FETCH_BY_SLUG, {
    variables: { slug: slug },
    pollInterval: poll,
    displayName: 'useIncidentBySlug',
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    skip: skip,
    onError(err) {
      console.log('ERROR useQuery-useIncidentBySlug: ', err)
    }
  })
  return { incidentBySlug, errorSlug, loadingSlug }
}

export default useIncidentBySlug
