//@ts-nocheck

/**
 * src/utilities/Zones.js
 *
 */

const rad2deg = (radian) => radian * 57.295779513

const deg2rad = (degree) => degree * 0.01745329252

const getCoordinateOffset = (latitude, longitude, bearing, distance) => {
  let radius = 3963.1676 // distance in miles
  // radius = 6378.1; // distance in kilometers

  // New latitude in degrees.
  let new_latitude = rad2deg(
    Math.asin(
      Math.sin(deg2rad(latitude)) * Math.cos(distance / radius) +
        Math.cos(deg2rad(latitude)) *
          Math.sin(distance / radius) *
          Math.cos(deg2rad(bearing))
    )
  )

  // New longitude in degrees.
  let new_longitude = rad2deg(
    deg2rad(longitude) +
      Math.atan2(
        Math.sin(deg2rad(bearing)) *
          Math.sin(distance / radius) *
          Math.cos(deg2rad(latitude)),
        Math.cos(distance / radius) -
          Math.sin(deg2rad(latitude)) * Math.sin(deg2rad(new_latitude))
      )
  )

  return [new_latitude, new_longitude]
}

/**
 * Determine if zone has been entered.
 * @name didEnterFence
 * @param {array} point - an array with a lat and lng entry.
 * @param {array} fence - an array of arrays of lng/lat points.
 * @return {boolean} inFence
 */
export const didEnterFence = (point, fence) => {
  let x = point[0]
  let y = point[1]
  let inFence = false

  for (let i = 0, j = fence.length - 1; i < fence.length; j = i++) {
    let xi = fence[i][0]
    let yi = fence[i][1]
    let xj = fence[j][0]
    let yj = fence[j][1]

    let intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inFence = !inFence
  }
  return inFence
}

/**
 * Build a multi-sided geofence around a long/lat point.
 * @name buildGeoFence
 * @param {int} n - number of sides to the geofence.
 * @param {int} lat - latitude of the central point.
 * @param {int} lng - longitude of the central point.
 * @param {int} dist - diameter across the geofence in miles.
 * @return {array} geoFence - an array of array of lat/lng points.
 */
export const buildGeoFence = (n, lat, lng, dist) => {
  let geoFence = []
  let fenceSides = n || 4
  let bearing = 0
  let increment = 360 / fenceSides
  for (let i = 0; i < fenceSides; i++) {
    let point = getCoordinateOffset(lat, lng, bearing, dist)
    geoFence.push(point)
    bearing = bearing + increment
  }
  return geoFence
}

/**
 * Makes a geojson file for Mapbox from DB data
 * @name makeGeoJson
 * @param {array} data - an array of appliance objects from the appliance table
 * @return {obj} geoJsonData - a properly formatted geojson object
 */
export const makeGeoJson = (data) => {
  let features = data.map((arr) => {
    // let number = arr.st_number ? arr.st_number : ''
    // let name = arr.st_name ? arr.st_name : ''
    // let type = arr.st_type ? arr.st_type : ''
    // let streetLocation = `${number} ${name} ${type}`
    let tmp = {
      type: 'Feature',
      properties: {
        // location: streetLocation
      },
      geometry: {
        type: 'Point',
        coordinates: [arr.longitude, arr.latitude]
      }
    }
    return tmp
  })
  let geoJsonData = {
    type: 'FeatureCollection',
    features: features
  }
  console.log('🌾 geoJsonData: ', geoJsonData)
  return geoJsonData
}
