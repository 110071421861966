//@ts-nocheck

/**
 * src/hooks/use-hooks/use-get-appliances.js
 *
 * Get appliances
 */

import { useQuery } from '@apollo/client'
import {
  APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS,
  APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS
} from '../queries/appliances'
// import { getDistanceFromLatLonInKm } from '../utilities'

const DEBUG = process.env.DEBUG
const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

/**
 * Gets appliance data for non-wet-hydrants
 * @name useGetDryPlusAppliances
 * @param {int} - a department ID
 * @param {GPS} - GPS lat/lon {lat: 41.12, lon: -73.25}
 * @param {number} - radius in miles for fence
 * @return {array} - an array of appliance objects
 */
export const useGetDryPlusAppliances = (dept_id, gps, fenceRadius) => {
  const { loading, error, data } = useQuery(
    APPLIANCES_DRYPLUS_FETCH_BY_DEPT_AND_GPS,
    {
      variables: {
        dept_id: parseInt(dept_id),
        gps: gps,
        fenceRadius: fenceRadius
      },
      fetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )
  return { data, error, loading }
}

/**
 * Gets appliance data
 * @name useGetAppliances
 * @param {int} - a department ID
 * @param {string} - an appliance type (i.e. wet, dry, lockbox)
 * @param {string} - GPS lat/lon
 * @param {number} - radius in miles for fence
 * @return {array} - an array of appliance objects
 */
export const useGetAppliances = (dept_id, type, gps, fenceRadius) => {
  const { loading, error, data } = useQuery(
    APPLIANCES_FETCH_BY_DEPT_AND_TYPE_AND_GPS,
    {
      variables: {
        dept_id: parseInt(dept_id),
        type: type,
        gps: gps,
        fenceRadius: fenceRadius
      },
      fetchPolicy: 'cache-first',
      partialRefetch: true
    }
  )

  return { data, error, loading }

  // if (error) return <Error error={`${error}: dept_id = ${dept_id}`} />
  // if (loading) return <Loading loading={`${dept_id}`} />

  // let closestHydrant = []
  // let distanceToHydrant = 0

  // if (data) {
  //   const { appliancesByDeptIdAndType: appliances } = data
  //   if (appliances.length > 0) {
  //     log.debug('🛠 appliances: ', appliances)
  //     // Start building closestHydrant array and distanceToHydrant with
  //     // first initial values from appliances
  //     distanceToHydrant = getDistanceFromLatLonInKm(
  //       gps[0],
  //       gps[1],
  //       appliances[0].latitude,
  //       appliances[0].longitude
  //     )
  //     closestHydrant.push(appliances[0])
  //     // Loop thru appliances to get closest hydrant to incident GPS
  //     appliances.forEach((hydrant) => {
  //       let tmp = getDistanceFromLatLonInKm(
  //         gps[0],
  //         gps[1],
  //         hydrant.latitude,
  //         hydrant.longitude
  //       )
  //       if (tmp < distanceToHydrant) {
  //         closestHydrant.push(hydrant)
  //         closestHydrant.shift()
  //         distanceToHydrant = tmp
  //       }
  //     })
  //     log.debug('closestHydrant: ', closestHydrant)
  //     log.debug('distanceToHydrant: ', distanceToHydrant * 3280.84)
  //     return parseInt(distanceToHydrant * 3280.84)
  //   }
  // }
}
