/**
 * src/components/Error.js
 *
 * @module Error
 * @param {string} error - an error
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: var(--error-color);
  border-radius: 0.6rem;
  color: var(--primary-color);
  display: block;
  padding: 0.3rem 0;
  margin: 0.3rem;
  text-align: center;

  img {
    height: 5rem;
  }

  p {
    font-size: 1.3rem;
  }
`

const Error = ({ error }) => {
  return (
    <Wrapper>
      <img
        alt="Emoji: exploding head"
        src="https://d76b2d0qyue8q.cloudfront.net/media/exploding-head.svg"
      />
      <p>{`${error}`}</p>
    </Wrapper>
  )
}

Error.propTypes = {
  error: PropTypes.string
}

Error.displayName = 'Error'

export default Error
