//@ts-nocheck

/**
 * src/utilities/Sunset.js
 *
 * Sunrise and sunset will be reported for the same day except
 * that sunrise will be reported for the next day once local time
 * exceeds midnight.
 *
 * Usage:
 *
    if (sunset > now && now > sunrise) {
      // do something
    }
 */

import { getSunrise, getSunset } from 'sunrise-sunset-js'
import getHours from 'date-fns/getHours'
import addDays from 'date-fns/addDays'

let rawNow = new Date(Date.now())
let tzOffsetInHr = rawNow.getTimezoneOffset() / 60
let now = rawNow.toISOString()

// TODO: This is currently fixed to East Coast time zone. Need to fix this to
// the Department's lat/long.  Or, using the user's timezone offset, use
// https://github.com/romanyanke/daynight package to determine day/night.
// const latitude = 41.12
// const longitude = -73.26

export const getSunsetSunrise = (latitude = 41.12, longitude = -73.26) => {
  let sunset = getSunset(latitude, longitude, rawNow)
  const sunsetHour = getHours(sunset)
  if (sunsetHour + tzOffsetInHr >= 24) {
    sunset = addDays(sunset, 1).toISOString()
  } else {
    sunset = sunset.toISOString()
  }

  let sunrise = getSunrise(latitude, longitude).toISOString()

  return { sunset, sunrise, now }
}
