/**
 * src/mutations/toggleSleep.js
 *
 */

import gql from 'graphql-tag'

const TOGGLE_SLEEP = gql`
  mutation UserEdit(
    $user_id: ID!,
    $is_sleeping: Boolean
  ) {
    userEdit(
      user_id: $user_id,
      is_sleeping: $is_sleeping
  ) {
      user_id
      is_sleeping
    }
  }
`

export default TOGGLE_SLEEP
