/*
 * src/utilities/callTypeColors.js
 *
 */

const DARKRED = `
  background: rgba(153,3,38,1);
  background: -moz-linear-gradient(top, rgba(153,3,38,1) 0%, rgba(31,0,7,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(153,3,38,1)), color-stop(100%, rgba(31,0,7,1)));
  background: -webkit-linear-gradient(top, rgba(153,3,38,1) 0%, rgba(31,0,7,1) 100%);
  background: -o-linear-gradient(top, rgba(153,3,38,1) 0%, rgba(31,0,7,1) 100%);
  background: -ms-linear-gradient(top, rgba(153,3,38,1) 0%, rgba(31,0,7,1) 100%);
  background: linear-gradient(to bottom, rgba(153,3,38,1) 0%, rgba(31,0,7,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#990326', endColorstr='#1f0007', GradientType=0 );
`

const RED = `
  background: rgba(242,48,48,1);
  background: -moz-linear-gradient(top, rgba(242,48,48,1) 0%, rgba(178,34,34,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(242,48,48,1)), color-stop(100%, rgba(178,34,34,1)));
  background: -webkit-linear-gradient(top, rgba(242,48,48,1) 0%, rgba(178,34,34,1) 100%);
  background: -o-linear-gradient(top, rgba(242,48,48,1) 0%, rgba(178,34,34,1) 100%);
  background: -ms-linear-gradient(top, rgba(242,48,48,1) 0%, rgba(178,34,34,1) 100%);
  background: linear-gradient(to bottom, rgba(242,48,48,1) 0%, rgba(178,34,34,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f23030', endColorstr='#b22222', GradientType=0 );
`

const ORANGE = `
  background: rgba(255,166,0,1);
  background: -moz-linear-gradient(top, rgba(255,166,0,1) 0%, rgba(238,119,0,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,166,0,1)), color-stop(100%, rgba(238,119,0,1)));
  background: -webkit-linear-gradient(top, rgba(255,166,0,1) 0%, rgba(238,119,0,1) 100%);
  background: -o-linear-gradient(top, rgba(255,166,0,1) 0%, rgba(238,119,0,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,166,0,1) 0%, rgba(238,119,0,1) 100%);
  background: linear-gradient(to bottom, rgba(255,166,0,1) 0%, rgba(238,119,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa600', endColorstr='#ee7700', GradientType=0 );
`

const GREEN = `
  background: rgba(39,174,95,1);
  background: -moz-linear-gradient(top, rgba(39,174,95,1) 0%, rgba(25,107,59,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(39,174,95,1)), color-stop(100%, rgba(25,107,59,1)));
  background: -webkit-linear-gradient(top, rgba(39,174,95,1) 0%, rgba(25,107,59,1) 100%);
  background: -o-linear-gradient(top, rgba(39,174,95,1) 0%, rgba(25,107,59,1) 100%);
  background: -ms-linear-gradient(top, rgba(39,174,95,1) 0%, rgba(25,107,59,1) 100%);
  background: linear-gradient(to bottom, rgba(39,174,95,1) 0%, rgba(25,107,59,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27ae5f', endColorstr='#196b3b', GradientType=0 );
`

const BLUE = `
  background: rgba(73,155,234,1);
  background: -moz-linear-gradient(top, rgba(73,155,234,1) 0%, rgba(22,95,173,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(73,155,234,1)), color-stop(100%, rgba(22,95,173,1)));
  background: -webkit-linear-gradient(top, rgba(73,155,234,1) 0%, rgba(22,95,173,1) 100%);
  background: -o-linear-gradient(top, rgba(73,155,234,1) 0%, rgba(22,95,173,1) 100%);
  background: -ms-linear-gradient(top, rgba(73,155,234,1) 0%, rgba(22,95,173,1) 100%);
  background: linear-gradient(to bottom, rgba(73,155,234,1) 0%, rgba(22,95,173,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#165fad', GradientType=0 );
`

const PURPLE = `
  background: rgba(180,25,245,1);
  background: -moz-linear-gradient(top, rgba(180,25,245,1) 0%, rgba(125,12,215,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(180,25,245,1)), color-stop(100%, rgba(125,12,215,1)));
  background: -webkit-linear-gradient(top, rgba(180,25,245,1) 0%, rgba(125,12,215,1) 100%);
  background: -o-linear-gradient(top, rgba(180,25,245,1) 0%, rgba(125,12,215,1) 100%);
  background: -ms-linear-gradient(top, rgba(180,25,245,1) 0%, rgba(125,12,215,1) 100%);
  background: linear-gradient(to bottom, rgba(180,25,245,1) 0%, rgba(125,12,215,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b419f5', endColorstr='#7d0cd7', GradientType=0 );
`

const GREY = `
  background: rgba(209,207,209,1);
  background: -moz-linear-gradient(top, rgba(209,207,209,1) 0%, rgba(158,158,158,1) 47%, rgba(82,82,82,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(209,207,209,1)), color-stop(47%, rgba(158,158,158,1)), color-stop(100%, rgba(82,82,82,1)));
  background: -webkit-linear-gradient(top, rgba(209,207,209,1) 0%, rgba(158,158,158,1) 47%, rgba(82,82,82,1) 100%);
  background: -o-linear-gradient(top, rgba(209,207,209,1) 0%, rgba(158,158,158,1) 47%, rgba(82,82,82,1) 100%);
  background: -ms-linear-gradient(top, rgba(209,207,209,1) 0%, rgba(158,158,158,1) 47%, rgba(82,82,82,1) 100%);
  background: linear-gradient(to bottom, rgba(209,207,209,1) 0%, rgba(158,158,158,1) 47%, rgba(82,82,82,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1cfd1', endColorstr='#525252', GradientType=0 );
`

const BROWN = `
  background: rgba(100,78,55,1);
  background: -moz-linear-gradient(top, rgba(100,78,55,1) 0%, rgba(66,45,42,1) 67%, rgba(50,38,38,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(100,78,55,1)), color-stop(67%, rgba(66,45,42,1)), color-stop(100%, rgba(50,38,38,1)));
  background: -webkit-linear-gradient(top, rgba(100,78,55,1) 0%, rgba(66,45,42,1) 67%, rgba(50,38,38,1) 100%);
  background: -o-linear-gradient(top, rgba(100,78,55,1) 0%, rgba(66,45,42,1) 67%, rgba(50,38,38,1) 100%);
  background: -ms-linear-gradient(top, rgba(100,78,55,1) 0%, rgba(66,45,42,1) 67%, rgba(50,38,38,1) 100%);
  background: linear-gradient(to bottom, rgba(100,78,55,1) 0%, rgba(66,45,42,1) 67%, rgba(50,38,38,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6E4E37', endColorstr='#322626', GradientType=0 );
`

// input a severity, return a gradient
export const getAlarmColor = (severity) => {
  // set background to a specific color based on incident severity
  // BROWN is reserved for old incidents that did not have an assigned severity
  let alarmColor = GREY // set GREY as default
  if (severity === 'DARKRED') {
    alarmColor = DARKRED
  } else if (severity === 'RED') {
    alarmColor = RED
  } else if (severity === 'ORANGE') {
    alarmColor = ORANGE
  } else if (severity === 'GREEN') {
    alarmColor = GREEN
  } else if (severity === 'BLUE') {
    alarmColor = BLUE
  } else if (severity === 'PURPLE') {
    alarmColor = PURPLE
  } else if (severity === 'BROWN') {
    alarmColor = BROWN
  } else if (severity === 'GRAY' || severity === 'GREY') {
    alarmColor = GREY
  } else {
    alarmColor = BROWN
  }
  return alarmColor
}
