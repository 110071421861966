/**
 * src/utilities/Breakpoints.js
 *
 * https://www.styled-components.com/docs/advanced#media-templates
 */

import { css } from 'styled-components'

const size = {
  desktop: 992,
  tablet: 768,
  phone: 576
}

export const mediaAbove = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const mediaBelow = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${size[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})
