//@ts-nocheck

/**
 * src/containers/Incident/Menu.js
 *
 * @module Menu.js
 *
 */

import React, { Fragment, useContext, useEffect, useState } from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import DispatchRecording from './DispatchRecording'
import Notifications from './Notifications'

import { A, Links, Modal, ModalContent, ModalCloseX } from '../../components'
import { capitalize, retry } from '../../utilities'
import { useTheme } from '../../ThemeContext'
import { UserContext } from '../App'

// IncidentList is lazy loaded as a component later on a button click
const IncidentList = null
const Responses = null
// import Responses from '../Respond/Responses'

const DEBUG = process.env.DEBUG
const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

const MenuBar = styled.nav`
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  display: flex;
  justify-content: space-between;
`

const SmButton = styled.div`
  background-color: firebrick;
  border-radius: 0.6rem;
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0.5rem 0;
  padding: 0.4rem;
  text-align: center;
  width: 6.5rem;
  &:active {
    background-color: var(--wizard-grey);
  }

  &:hover {
    background-color: var(--wizard-grey);
  }
`

// These buttons are styled the same as the button found in containers/Incident/DispatchRecording.js(Button). Make changes to both and should be refactored.
// TODO: refactor
const TopButtons = styled.button`
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  background-color: #0055c6;
  border-radius: 0.6rem;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.2em;
  font-family: 'Arial', 'Trebuchet MS', sans-serif;
  padding: 0.6rem;

  &:active {
    background-color: firebrick;
    filter: brightness(120%) saturate(120%);
  }

  &:hover {
    background-color: firebrick;
    filter: brightness(120%) saturate(120%);
  }
`

const Menu = ({ cancelLocationWatch, theme, incident, userLocation }) => {
  const [revealNotificationsButton, setRevealNotificationsButton] = useState(false)
  const [revealMenuModal, setRevealMenuModal] = useState(false)
  const [revealIncidentModal, setRevealIncidentModal] = useState(false)
  const [revealRespondModal, setRevealRespondModal] = useState(false)
  const { userByUslug: user } = useContext(UserContext)
  const { recordings, department } = incident
  const themeToggle = useTheme()

  log.debug('🏠 department.dept_id: ', department.dept_id)
  log.debug('👨 userLocation in Menu: ', userLocation)

  const formattedMobile = parsePhoneNumberFromString(user.mobile_num, 'US').formatNational()

  function displayConfirmNotification() {
    if ('serviceWorker' in navigator) {
      let options = {
        body: 'You successfully subscribed to FireTek notifications.',
        icon: 'images/apple-touch-icon-76x76.png',
        image: 'images/apple-touch-icon-76x76.png',
        vibrate: [100, 50, 200],
        badge: 'images/apple-touch-icon-76x76.png'
      }
      navigator.serviceWorker.ready.then(function (swreg) {
        swreg.showNotification('SW Successfully subscribed', options)
      })
    }
  }

  function askForNotificationPermission() {
    Notification.requestPermission().then(function (permission) {
      if (permission !== 'granted') {
        setRevealNotificationsButton(true)
      } else {
        setRevealNotificationsButton(false)
        setRevealMenuModal(false)
        displayConfirmNotification()
      }
      log.debug('❗ Notification.permission: ', permission)
    })
  }

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        setRevealNotificationsButton(false)
        // navigator.serviceWorker.getRegistration().then(function (reg) {
        //   reg.showNotification('Hello world!')
        // })
      } else {
        setRevealNotificationsButton(true)
      }
      log.debug('❗ 1st check for Notification.permission: ', Notification.permission)
    }
  }, [])

  return (
    <Fragment>
      {/* Modal for Menu button */}
      <Modal flag={revealMenuModal}>
        <ModalContent>
          <ModalCloseX onPointerDown={() => setRevealMenuModal(false)}>&times;</ModalCloseX>
          <div>
            <Notifications inc_id={user.user_id} />
            <p>
              {user.first_name} {user.last_name}
              <br />
              {user.rank}
              <br />
              {formattedMobile}
              <br />
              {user.carrier.carrier_name}
              <br />
              {user.email}
              <br />
              {user.station.sta_name}
              <br />
              {capitalize(user.device_os)} phone
              <br />
            </p>
            <div style={{ marginLeft: '3rem', lineHeight: '2rem' }}>
              <Links to={''} id="darkToggle" onPointerDown={() => themeToggle.toggle()}>
                <SmButton>{theme.mode === 'dark' ? 'Light mode' : 'Dark mode'}</SmButton>
              </Links>
              <Links to={`/u/${incident.slug}/${user.uslug}`}>
                <SmButton>Edit profile</SmButton>
              </Links>
              <Links to={`/saas`}>
                <SmButton>Legal</SmButton>
              </Links>
              <Links to={`/sms-compose`}>
                <SmButton>Send SMS</SmButton>
              </Links>
              <Links to={`/logout`}>
                <SmButton>Log out</SmButton>
              </Links>
              {user.is_admin ? (
                <Fragment>
                  <A href="https://admin.rt.vg">
                    <SmButton>Admin</SmButton>
                  </A>
                  {revealNotificationsButton && (
                    <SmButton onClick={askForNotificationPermission}>Enable notifications</SmButton>
                  )}
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
        </ModalContent>
      </Modal>

      {/* Modal for IncidentList button */}
      <Modal flag={revealIncidentModal} onClick={() => setRevealIncidentModal(false)}>
        <ModalContent>
          <ModalCloseX onPointerDown={() => setRevealIncidentModal(false)}>&times;</ModalCloseX>
          <div>
            {/* <IncidentsList should have been lazy loaded on button click */}
            {IncidentList ? <IncidentList incident={incident} onClick={() => setRevealIncidentModal(false)} /> : ''}
          </div>
        </ModalContent>
      </Modal>

      {/* Modal for Respond button */}
      <Modal flag={revealRespondModal} onClick={() => setRevealRespondModal(false)}>
        <ModalContent>
          <ModalCloseX onPointerDown={() => setRevealRespondModal(false)}>&times;</ModalCloseX>
          <div>
            {/* <Responses should have been lazy loaded on button click */}
            {Responses ? (
              <Responses
                incident={incident}
                userLocation={userLocation}
                cancelLocationWatch={cancelLocationWatch}
                onClick={() => setRevealRespondModal(false)}
              />
            ) : (
              ''
            )}
          </div>
        </ModalContent>
      </Modal>

      {/* Layout buttons in the menu bar at top of page */}
      <MenuBar>
        <section>
          <TopButtons id="modalButton1" onPointerDown={() => setRevealMenuModal(true)}>
            Menu
          </TopButtons>
        </section>

        {/* Check if inc_id > 3481 because prior incidents did not have recordings */}
        {parseInt(incident.inc_id) > 3481
          ? recordings &&
            recordings[0].is_visible && (
              <section>
                <DispatchRecording incident={incident} />
              </section>
            )
          : null}

        <section>
          <TopButtons
            id="modalButton2"
            onPointerDown={() => {
              setRevealIncidentModal(true)
              {
                /* Lazy load <IncidentsList> on button click */
              }
              IncidentList = React.lazy(() => retry(() => import('./IncidentList')))
            }}
          >
            Incidents
          </TopButtons>
        </section>

        {user.is_volley ? (
          <section>
            <TopButtons
              id="modalButton3"
              onPointerDown={() => {
                {
                  /* Lazy load <Responses> on button click */
                }
                Responses = React.lazy(() => retry(() => import('../Respond/Responses')))
                setRevealRespondModal(true)
              }}
            >
              Respond
            </TopButtons>
          </section>
        ) : (
          ''
        )}
      </MenuBar>
    </Fragment>
  )
}

Menu.displayName = 'Menu'

Menu.propTypes = {
  cancelLocationWatch: PropTypes.any,
  incident: PropTypes.object,
  theme: PropTypes.object,
  userLocation: PropTypes.object
}

export default withTheme(Menu)
