//@ts-nocheck

/**
 * src/containers/Incident/Incident.js
 *
 */

import format from 'date-fns/format'
import distanceInWordsToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useWatchLocation } from '../../hooks/use-watch-location'
import { retry, runWakeLock } from '../../utilities'

const DEBUG = process.env.DEBUG

const log = require('loglevel')
log.setLevel('warn')

let POLL_INTERVAL = 2500
if (DEBUG) {
  log.enableAll()
}

// set local storage with this URL
localStorage.setItem('pathname', location.pathname)

import { getAlarmColor } from '../../utilities/callTypeColors'
import Assignment from './Assignment'
import Menu from './Menu'
import NoPerms from './NoPerms'
// import MostRecent from './MostRecent'
import Remarks from './Remarks'
// import Response from './Response'

import { Loading, NotFound } from '../../components'

import useIncidentBySlug from '../../hooks/use-incident-by-slug'
import { sectionHeading } from '../../themes/theme'
import { UserContext } from '../App'

import MapBox2D from '../Maps/MapBox2D'
import MapBoxSat from '../Maps/MapBoxSat'

const History = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ './History'))
)

const NearWetHydrant = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ './NearWetHydrant'))
)

const RadioPlay = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ './RadioPlay'))
)
const Responders = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ '../Respond/Responders'))
)
const ResponderBar = React.lazy(() =>
  retry(() => import(/* webpackPreload: true */ '../Respond/ResponderBar'))
)

const Card = styled.div`
  border: thin solid ${sectionHeading};
  border-radius: 0.5rem;
  box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 1);
  display: block;
  margin: 0.3rem;
  padding: 0;
`

const IncHeading = styled.div`
  border-radius: 0.5rem;
  padding: 0.7rem 0;
  color: white;
  text-align: center;
  letter-spacing: 0.3rem;
  background-color: ${(props) => props.alarmColor || 'green'};
`

const IncType = styled.div`
  font-size: 2rem;
  grid-area: 1/2/2/3;
  font-family: 'Georgia', sans-serif;
  padding: 0 0.5rem;
  overflow-wrap: anywhere;
  word-break: break-word;

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    font-size: 2rem;
  }
`

const IncTimeAgo = styled.div`
  font-size: 1rem;
  grid-area: 2/2/3/3;
  letter-spacing: 5px;
  display: flex;
  justify-content: center;
  padding: 0;

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    font-size: 1rem;
  }
`

const IncTimeDate = styled.div`
  font-size: 1rem;
  grid-area: 3/2/4/3;

  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    font-size: 1rem;
  }
`

const IncDetails = styled.div`
  section {
    font-size: 1em;
  }

  section#history {
    border-bottom: 0;
  }

  section h3 {
    margin: 0.5rem 0 0.5rem 0.8rem;
    font-weight: normal;
  }

  .incidentContent {
    margin: 0 0 0.4em 0.8rem;
    text-align: center;
  }

  .incidentAssignment {
    margin: 0 0.5rem 0.4em 1.3rem;
    text-align: left;
  }

  .incidentHistory {
    margin: 0;
    overflow-wrap: anywhere;
    text-align: center;
    word-break: break-word;
  }

  .incidentLocation {
    margin: 0 0 0.4em 0.8rem;
    text-align: center;
    font-size: 1.2rem;
  }

  .upgradeNotice {
    margin: 0 0 0.4em 0.8rem;
    text-align: left;
    font-size: 1rem;
    padding: 0.2rem;
  }

  .incidentRemarks {
    margin: 0 0.5rem 0.4em 1.3rem;
    text-align: left;
  }

  .incidentRadio {
    margin: 0;
    text-align: center;
  }

  .incidentResponses {
    margin: 0 0.6rem 0.4em 0.8rem;
    padding: 0.2rem;
  }

  .incidentDispatchId {
    text-align: center;
    font-size: 1.2em;
  }
`

const timeAgo = (zulu) => {
  // parse Zulu string time to date object
  let timeOut = parseISO(zulu)
  // compare distance between now and dispatch timeout
  // let timeDiff = distanceInWordsToNow(timeOut, { addSuffix: true }).split(' ')
  let timeDiff = distanceInWordsToNow(timeOut, { addSuffix: true })
  // map over words to capitalize first letter
  return timeDiff
  // .map(el => (el.length > 0 ? el[0].toUpperCase() + el.slice(1) : ''))
  // .join(' ')
}

// wakeLock API call - only useful for Android, not iOs
if ('wakeLock' in navigator) {
  runWakeLock(1000 * 60 * 15)
} else {
  log.debug('wakeLock API is not supported 😩')
}

const Incident = () => {
  const [showMaps, setShowMaps] = useState(true)
  const { slug } = useParams()
  const { userByUslug } = useContext(UserContext)

  // settings is an optional argument
  const settings = useMemo(() => {
    return { enableHighAccuracy: true }
  }, [])

  const { userLocation, cancelLocationWatch, error } =
    useWatchLocation(settings)

  // Create an array of department IDs that the user belongs to
  const userDepts = userByUslug.department.map((dept) => dept.dept_id)

  const { incidentBySlug, errorSlug, loadingSlug } = useIncidentBySlug({
    slug: slug,
    poll: POLL_INTERVAL
  })

  // if (errorSlug) return <Error error={`${errorSlug.graphQLErrors}: slug = ${slug}`} />
  if (loadingSlug || errorSlug) return <Loading />

  const { incidentBySlug: incident } = incidentBySlug
  if (incident) {
    log.debug('👻 incident: ', incident)
  }

  if (incident === null) {
    return <NotFound />
  }

  const { radio_freq, responses_users, department } = incident

  const descriptiveCategory =
    incident.inc_description.length > incident.inc_category.length
      ? incident.inc_description
      : incident.inc_category

  // Determine if user is allowed to see this incident.
  // If incident.department.dept_id is included in user.department[i].dept_id,
  // then accum will be non-zero, therefore show the incident
  let accum = 0
  if (userByUslug) {
    userByUslug.department.forEach((dept) => {
      if (parseInt(dept.dept_id) === parseInt(department.dept_id)) {
        accum += parseInt(dept.dept_id)
      }
    })
  }

  if (accum === 0) {
    // user is not is this department, so should NOT see the incident
    return <NoPerms />
  } else {
    // user is is this department
    // onTouchStart={() => handleTouchStart()}
    // onWheel={() => handleTouchStart()}
    // onLoad={() => handleTouchStart()}
    // {/* <MostRecent userDepts={userDepts} slug={incident.slug} /> */}
    return (
      <React.Fragment>
        <Menu
          cancelLocationWatch={cancelLocationWatch}
          incident={incident}
          userLocation={userLocation}
        />
        <IncDetails>
          <Card>
            <section>
              <IncHeading
                alarmColor={getAlarmColor(incident.severity.severity)}
              >
                <IncType>{descriptiveCategory}</IncType>
                <IncTimeAgo>{timeAgo(incident.timeout)}</IncTimeAgo>
                <IncTimeDate>
                  {format(new Date(incident.timeout), 'EEE, MMM dd - HH:mm')}
                </IncTimeDate>
              </IncHeading>
            </section>
          </Card>
          <Card>
            <section>
              <h3>FireTek Upgrade Notice</h3>
              <div className="upgradeNotice">
                Please upgrade to the new version of FireTek. This version of
                FireTek will be discontinued soon.
                <br />
                <br />
                You can download it from the{' '}
                <a href="https://apps.apple.com/us/app/firetek/id1659482947">
                  Apple App Store
                </a>{' '}
                or{' '}
                <a href="https://play.google.com/store/apps/details?id=io.firetek.sizeup">
                  Google Play
                </a>
                .
                <br />
                <br />
                After downloading and installing the new app, log-in with your
                email and the temporary password: <strong>nutmeg24</strong>.
                You'll be prompted to change your password after logging in.
                <br />
                <br />
                Please contact{' '}
                <a href="mailto:support@firetek.io">support@firetek.io</a> if
                you have any questions.
              </div>
            </section>
          </Card>
          <Card>
            <section>
              <h3>Location</h3>
              <div className="incidentLocation">
                {incident.location}
                <br />
                {incident.city}
                <br />
                {incident.location &&
                incident.location !== incident.location_name
                  ? incident.location_name
                  : ''}
                {incident.apt_no && (
                  <span>
                    <br />
                    Apt#: {incident.apt_no}
                  </span>
                )}
              </div>
              {<NearWetHydrant inc_id={incident.inc_id} /> !== '' ? (
                <NearWetHydrant inc_id={incident.inc_id} />
              ) : (
                ''
              )}
            </section>
          </Card>
          {incident.cross_street && (
            <Card>
              <section>
                <h3>Cross Street</h3>
                <div className="incidentContent">{incident.cross_street}</div>
              </section>
            </Card>
          )}
          {incident.assignment.length > 0 && (
            <Card>
              <section>
                <h3>Assignment</h3>
                <div className="incidentAssignment">
                  <Assignment incident={incident} />
                </div>
              </section>
            </Card>
          )}
          {/* This section is seen by everyone if there are responders */}
          {responses_users[0] !== undefined
            ? userByUslug.is_enabled && (
                <Card>
                  <section>
                    <h3>Responders</h3>
                    <div className="incidentResponses">
                      <Responders incident={incident} />
                    </div>
                  </section>
                </Card>
              )
            : ''}
          {incident.remarks.length > 0 && (
            <Card>
              <section>
                <h3>Remarks</h3>
                <div className="incidentRemarks">
                  <Remarks incident={incident} />
                </div>
              </section>
            </Card>
          )}
          {radio_freq && (
            <Card>
              <section>
                <h3>Live Radio</h3>
                <div className="incidentRadio">
                  <React.Fragment>
                    <RadioPlay incident={incident} />
                  </React.Fragment>
                </div>
              </section>
            </Card>
          )}
          <Card>
            {showMaps && (
              <section id="maps">
                <Fragment>
                  <MapBox2D
                    inc={incident}
                    userLocation={userLocation}
                    userByUslug={userByUslug}
                  />
                  <MapBoxSat inc={incident} userLocation={userLocation} />
                </Fragment>
              </section>
            )}
          </Card>
          <Card>
            <section id="history">
              <h3>Recent History</h3>
              <div className="incidentHistory">
                <History
                  dept_id={incident.department.dept_id}
                  location={incident.location}
                />
              </div>
            </section>
          </Card>
          <Card>
            <section id="dispatchId">
              <h3>Department and Incident Number</h3>
              <div className="incidentDispatchId">
                {incident.department.dept_name}
                &nbsp; &nbsp;
                <span style={{ whiteSpace: 'nowrap' }}>
                  {incident.department.disp_id === '1' &&
                    format(new Date(incident.timeout), 'yy') + '-'}
                  {incident.fd_dispatch_id}
                </span>
              </div>
            </section>
          </Card>
        </IncDetails>
      </React.Fragment>
    )
  }
}
// <span className="incidentDispatchId" style={{fontSize: '1.17em', marginRight: '39rem'}}>

Incident.displayName = 'Incident'

Incident.propTypes = {
  children: PropTypes.object
}

export default React.memo(Incident)

// <Card>
//   <section>
//     <Response incident={incident} />
//   </section>
// </Card>
