//@ts-nocheck

/**
 * src/queries/incidents.js
 *
 */

import { gql } from '@apollo/client'

const INCIDENTS_FETCH_BY_LOCATION = gql`
  query IncidentsByLocation($limit: Int, $location: String, $dept_id: Int!) {
    incidentsByLocation(limit: $limit, location: $location, dept_id: $dept_id) {
      inc_id
      inc_category
      inc_type_code
      location
      location_name
      city
      slug
      responses_users {
        resp_user_id
        onscene_verified
      }
      department {
        address1
      }
      severity {
        severity
      }
      timeout
      created_at
    }
  }
`

const INCIDENTS_FETCH_BY_DEPT_ID = gql`
  query IncidentsByDeptId($limit: Int, $dept_id: ID) {
    incidentsByDeptId(limit: $limit, dept_id: $dept_id) {
      inc_id
      fd_dispatch_id
      slug
      timeout
      radio_freq
      inc_category
      inc_description
      inc_type_code
      apt_no
      location
      location_name
      city
      zip
      cross_street
      map_ref
      latitude
      longitude
      test_call
      assignment {
        assignment
      }
      department {
        dept_name
        dept_id
      }
      severity {
        severity
      }
    }
  }
`

const INCIDENTS_FETCH_BY_DEPT_IDS = gql`
  query IncidentsByDeptIds($limit: Int, $ids: [ID]) {
    incidentsByDeptIds(limit: $limit, ids: $ids) {
      inc_id
      fd_dispatch_id
      slug
      timeout
      radio_freq
      inc_category
      inc_description
      inc_type_code
      apt_no
      location
      location_name
      city
      zip
      cross_street
      map_ref
      latitude
      longitude
      test_call
      assignment {
        assignment
      }
      department {
        dept_id
        dept_name
      }
      severity {
        severity
      }
    }
  }
`

const INCIDENT_FETCH_BY_INC_ID = gql`
  query Inc($inc_id: ID!) {
    incident(inc_id: $inc_id) {
      inc_id
      slug
      inc_category
      inc_type_code
      location
      location_name
      apt_no
      city
      zip
      inc_description
      timeout
      cross_street
      map_ref
      latitude
      longitude
      test_call
      radio_freq
      assignment {
        inc_assignment_id
        assignment
        created_at
      }
      remarks {
        inc_remark_id
        remark
        created_at
      }
      recordings {
        inc_recording_id
        url
        is_visible
        recording_type
        created_at
      }
      status {
        inc_status_id
        pending
        active
        closed
        created_at
      }
      department {
        dept_id
        dept_abbr
        dept_name
        longitude
        latitude
      }
      severity {
        severity
      }
      responses_users {
        resp_user_id
        user_id
        inc_id
        respond_direct
        init_resp_timestamp
        init_resp_gps
        onscene_resp_timestamp
        onscene_resp_gps
        onscene_verified
        in_stn_timestamp
        in_stn_gps
        in_stn_verified
        cancelled_timestamp
        cancelled_gps
        cancelled_verified
        closing_resp_timestamp
        closing_resp_gps
        eta
        user {
          user_id
          first_name
          last_name
        }
      }
    }
  }
`

const INCIDENT_FETCH_BY_SLUG = gql`
  query IncidentBySlug($slug: String!) {
    incidentBySlug(slug: $slug) {
      inc_id
      fd_dispatch_id
      dispatch_id
      slug
      inc_category
      inc_type_code
      location
      location_name
      apt_no
      city
      zip
      inc_description
      timeout
      cross_street
      map_ref
      latitude
      longitude
      test_call
      radio_freq
      assignment {
        inc_assignment_id
        assignment
        created_at
      }
      remarks {
        inc_remark_id
        remark
        created_at
      }
      recordings {
        inc_recording_id
        url
        is_visible
        recording_type
        created_at
      }
      severity {
        severity
      }
      status {
        inc_status_id
        pending
        active
        closed
        created_at
      }
      department {
        dept_id
        dept_abbr
        dept_name
        longitude
        latitude
        local_id
        disp_id
      }
      responses_users {
        resp_user_id
        user_id
        inc_id
        respond_direct
        init_resp_timestamp
        init_resp_gps
        onscene_resp_timestamp
        onscene_resp_gps
        onscene_verified
        in_stn_timestamp
        in_stn_gps
        in_stn_verified
        cancelled_timestamp
        cancelled_gps
        cancelled_verified
        closing_resp_timestamp
        closing_resp_gps
        eta
        user {
          user_id
          first_name
          last_name
        }
      }
    }
  }
`

const INCIDENT_FETCH_APPLIANCES = gql`
  query TrackClosestAppliancesForIncident($inc_id: Int!) {
    track_closest_appliances_for_incident(inc_id: $inc_id) {
      dist_in_feet
      appl_id {
        appl_id
        type
        latitude
        longitude
        st_number
        st_name
        st_type
        is_active
      }
      cl_id
    }
  }
`

export {
  INCIDENT_FETCH_BY_INC_ID,
  INCIDENT_FETCH_BY_SLUG,
  INCIDENTS_FETCH_BY_DEPT_ID,
  INCIDENTS_FETCH_BY_DEPT_IDS,
  INCIDENTS_FETCH_BY_LOCATION,
  INCIDENT_FETCH_APPLIANCES
}
