/**
 * src/components/As.js
 *
 * Usage:
 *
 *   <A color="red" hoverColor="green" href="https://admin.rt.vg">Admin</A>
 */

import styled from 'styled-components'

import { linkTextColor, linkHoverTextColor } from '../themes/theme'

const A = styled.a`
  color: ${(props) => props.color || linkTextColor};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.hoverColor || linkHoverTextColor};
    filter: brightness(120%) saturate(120%);
  }
  &:active {
    color: ${(props) => props.hoverColor || linkHoverTextColor};
    filter: brightness(120%) saturate(120%);
  }
`

export default A
