//@ts-nocheck

/**
 * Utilites for mapping
 *
 */

const MAPBOX_ACCESS_TOKEN = `pk.eyJ1IjoiZmlyZXRlayIsImEiOiJja2liNmZlYXQwNDBzMnFwZjRydzJobWVnIn0.9K_48KSsAH3ZJUa1m0o4ew`
const mbxMatrix = require('@mapbox/mapbox-sdk/services/matrix')

/**
 * @name mbxMatrixService
 * @param {object} accessToken - access token for use with MapBox
 * @return {object} matrix - a matrix object used for distance/duration calcs
 */
const mbxMatrixService = mbxMatrix({ accessToken: MAPBOX_ACCESS_TOKEN })

/**
 * @name mbxGetETA
 * @param {object} userGPS - user's present decimal GPS (expect keys lat and lng).
 * @param {object} destGPS - destination decimal GPS (expect keys lat and lng).
 * @param {function} callback - a mapbox matrix object
 *
 * @example
 * let dtGPS = { lat: 41.13608, lng: -73.28831}
 * let usGPS = { lat: 40.05832, lng: -74.40566}
 * mbxGetETA(usGPS, dtGPS, callback => {
     console.log('matrix: ', callback)
   })
 *
 */
export const mbxGetETA = async (userGPS, destGPS, callback) => {
  let uGPS
  let dGPS

  if (typeof userGPS === 'string') {
    uGPS = JSON.parse(userGPS)
  } else {
    uGPS = userGPS
  }

  if (typeof destGPS === 'string') {
    dGPS = JSON.parse(destGPS)
  } else {
    dGPS = destGPS
  }

  await mbxMatrixService
    .getMatrix({
      points: [
        {
          coordinates: [parseFloat(uGPS.lng), parseFloat(uGPS.lat)],
          approach: 'unrestricted'
        },
        {
          coordinates: [parseFloat(dGPS.lng), parseFloat(dGPS.lat)],
          approach: 'unrestricted'
        }
      ],
      sources: [0],
      destinations: [1],
      profile: 'driving',
      annotations: ['distance', 'duration']
    })
    .send()
    .then((response) => callback(response.body))
    .catch((error) => console.error(error.body))
}

/**
 * @function
 * @name deg2rad
 * @param {number} deg - degrees.
 * @returns {number} radian
 */
export const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

/**
 * @function
 * @name getDistanceFromLatLonInKm.
 * @param {float} lat1 - latitude 1.
 * @param {float} lon1 - longitude 1.
 * @param {float} lat2 - latitude 2.
 * @param {float} lon2 - longitude 2.
 * @returns {float} d - distance in kilometers.
 * requires: deg2rad
 */
export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  let R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1)
  let dLon = deg2rad(lon2 - lon1)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let d = R * c // Distance in km
  return d
}

/**
 * @function
 * @name getDistanceKmFromLatLon
 * @param {number} lat1 - starting latitude in decimal form
 * @param {number} lon1 - starting longitude in decimal form
 * @param {number} lat2 - ending latitude in decimal form
 * @param {number} lon2 - ending longitude in decimal form
 * @returns {number} d - distance in kilometers
 */
export const getDistanceKmFromLatLon = (lat1, lon1, lat2, lon2) => {
  let R = 6371 // Radius of the earth in km
  let dLat = (lat2 - lat1) * (Math.PI / 180)
  let dLon = (lon2 - lon1) * (Math.PI / 180)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((Math.PI / 180) * lat1) *
      Math.cos((Math.PI / 180) * lat2) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in km
}
