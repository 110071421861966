/**
 * src/ErrorBoundary.js
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    console.log('error, errorInfo: ', error, errorInfo)
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>An error occurred. Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    // if no error, render children untouched
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
}

ErrorBoundary.displayName = 'ErrorBoundary'

export { ErrorBoundary }
