/**
 * src/utilities/Retry.js
 *
 * Retry chunkload errors
 *
 * If the browser fails to download the module, it'll try again
 * 5 times with a 1 second delay between each attempt. If
 * even after 5 tries it import it, then an error is thrown.
 *
 * Usage:
 *
   import { retry } from '../../utilities'

   const Compose = React.lazy(() =>
     retry(() => import('../Sms/Compose')))
 *
 *
 * Source: https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
 */

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
