/**
 * src/components/index.js
 *
 */

import A from './As'
import Button from './Button'
import Container from './Container'
import Error from './Error'
import Flex from './Flex'
import Header from './Header'
import Heading from './Heading'
import Links from './Links'
import Loader from './Loader'
import Loading from './Loading'
import LoadingSuspense from './LoadingSuspense'
import NotFound from './NotFound'
import { Modal, ModalCloseX, ModalContent } from './Modals'

export {
  A,
  Button,
  Container,
  Error,
  Flex,
  Header,
  Heading,
  Links,
  Loader,
  Loading,
  LoadingSuspense,
  Modal,
  ModalCloseX,
  ModalContent,
  NotFound
}
