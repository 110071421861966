/**
 * src/components/Header.js
 *
 * @module Header.js
 *
 */

import * as React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Header = ({ className }) => (
  <header className={className}>
    <a href="https://firetek.io">
      <Image cloudName="firetek" publicId="home/firetek-banner_i82mwt">
        <Transformation width="130" crop="scale" quality="95" />
      </Image>
    </a>
  </header>
)

Header.propTypes = {
  className: PropTypes.string.isRequired
}

export default styled(Header)`
  background-color: var(--dark-bg);
  padding: 0.2rem 5%;

  box-shadow: 3px 3px 0.5em var(--dark-bg);
`
