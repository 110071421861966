//@ts-nocheck

/**
 * src/theme/globalStyles.js
 *
 */

import { createGlobalStyle } from 'styled-components'

import { backgroundColor, fontFamily } from './theme'
// import { mediaAbove, mediaBelow } from '../utilities'

const GlobalStyle = createGlobalStyle`

  :root {
    --light-bg: #fff;
    --dark-bg: #2f3640;
    --light-fg: #000;
    --dark-fg: #fff;

    --box-color: #52473c;
    --primary-color: #fff;
    --secondary-color: #F48977;
    --primary-complement-color: #413931;
    --secondary-complement-color: #fff8e8;
    --tertiary-color: #9b9987;
    --tertiary-complement-color: #fff3e8;

    --fire-engine-red: #d9170e;
    --fire-engine-blue: #0055c6;
    --fire-engine-yellow: #f4ac00;
    --wizard-grey: #535c68;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    touch-action: manipulation;
    -webkit-font-smoothing: antialiased;
    width: 100%;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    background: var(--tertiary-color);
    display: flex;
    flex-direction: column;
    font-family: ${fontFamily};
    margin: auto;
    min-height: 100vh;
    text-rendering: optimizeLegibility;
  }

  #root {
    display: flex;
    background: ${backgroundColor};
    min-height: 100vh;
    min-width: 50%;
  }

  code {
    font-family: monospace;
  }
`

export default GlobalStyle

//  was in body tag above
//     ${mediaAbove.desktop`
//       max-width: 1284px;
//     `}
//     ${mediaBelow.desktop`
//       max-width: 732px;
//     `}
//     ${mediaBelow.tablet`
//       max-width: 512px;
//     `}
