//@ts-nocheck

/**
 * src/containers/App/App.js
 *
 * @module App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Fragment, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useQuery } from '@apollo/client'

export const UserContext = React.createContext()

import { Loading, LoadingSuspense, NotFound } from '../../components'
import { ErrorBoundary } from '../../ErrorBoundary'
import { Incident } from '../Incident'
import { MyThemeProvider } from '../../ThemeContext'
import { retry } from '../../utilities'
import { USER_FETCH_BY_USLUG } from '../../queries/users'

const Compose = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "SMSCompose" */ '../Sms/Compose'))
)
const ForgotPasswd = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "ForgotPasswd" */ '../Auth/ForgotPasswd')
  )
)
const Home = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "Home" */ '../Home'))
)
const Login = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "Login" */ '../Auth/Login'))
)
const Logout = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "Logout" */ '../Auth/Logout'))
)
const ResetPasswd = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "ResetPasswd" */ '../Auth/ResetPasswd')
  )
)
const Saas = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "SAASAgreement" */ '../Saas'))
)
const UserEdit = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "UserEdit" */ '../User'))
)

const DEBUG = process.env.DEBUG
const NODE_ENV = process.env.NODE_ENV

const log = require('loglevel')
log.setLevel('warn')
if (DEBUG) {
  log.enableAll()
}

if (NODE_ENV !== 'production') {
  log.debug('NODE_ENV at App/index: ', NODE_ENV)
  log.debug('DEBUG at App/index: ', DEBUG)
}

export const App = () => {
  let uSlug, iSlug, deptId
  const pathItems = location.pathname.split('/')

  if (
    location.pathname.slice(0, 3) === '/s/' ||
    location.pathname.slice(0, 3) === '/u/'
  ) {
    if (pathItems[1] === 's' || pathItems[1] === 'u') {
      iSlug = pathItems[2]
      uSlug = pathItems[3]
      // first two digits of iSlug is hexidecimal, so convert to decimal and make an int
      deptId = parseInt(iSlug.slice(0, 2), 16)
      // set localStorage key for dept
      localStorage.setItem('dept', deptId)
    }
    log.debug('iSlug: ', iSlug)
    log.debug('uSlug: ', uSlug)
    log.debug('deptId: ', deptId)
  }

  const {
    loading: loadingUser,
    error: errorUser,
    data: userByUslug
  } = useQuery(USER_FETCH_BY_USLUG, {
    variables: { uslug: uSlug },
    partialRefetch: true,
    fetchPolicy: 'cache-first'
  })

  let userDetails = {}
  if (userByUslug) {
    Object.assign(userDetails, userByUslug)
    userDetails.deptId = deptId
    // set localStorage key for user
    localStorage.setItem('user', JSON.stringify(userByUslug))
    log.debug('userDetails: ', userDetails)
  }

  if (errorUser) {
    log.error('ERROR in errorUser: ', errorUser)

    // if (NODE_ENV === 'production') {
    //   return (
    //     <Fragment>
    //       <div>Your Internet connection seems sketchy. Try reloading.</div>
    //       <Button onClick={() => location.reload()}>Reload page</Button>
    //     </Fragment>
    //   )
    // }
  }
  if (loadingUser) return <Loading />

  return (
    <ErrorBoundary>
      <Fragment>
        <UserContext.Provider value={userDetails}>
          <MyThemeProvider>
            <Suspense fallback={<LoadingSuspense />}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/forgotpasswd" element={<ForgotPasswd />} />
                  <Route path="/resetpasswd/:token" element={<ResetPasswd />} />
                  <Route path="/s/:slug/:uslug" element={<Incident />} />
                  <Route path="/u/:slug/:uslug" element={<UserEdit />} />
                  <Route path="/saas" element={<Saas />} />
                  <Route path="/sms-compose" element={<Compose />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </Suspense>
          </MyThemeProvider>
        </UserContext.Provider>
      </Fragment>
    </ErrorBoundary>
  )
}
