/**
 * src/components/Switches.js
 *
 * Usage:
 *
import styled from 'styled-components'
import { Slider, Switch } from '../../components'

const SomeComponent = () => {
  return (
    <React.Fragment>
      <Switch>
        <input type="checkbox" id="is_sleeping" name="is_sleeping"
          value={user.is_sleeping} defaultChecked={user.is_sleeping} />
        <Slider />
      </Switch>

      <OtherContent>
        ...
      <OtherContent>
    </React.Fragment>
  )
}
 *
 */

import styled from 'styled-components'

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 6rem;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 6rem;
    transition: 0.4s;
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0.1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + ${Slider} {
    background-color: #32cd32;
  }

  input:focus + ${Slider} {
    box-shadow: 0 0 1px #32cd32;
  }

  input:checked + ${Slider}::before {
    transform: translateX(26px);
  }
`

export { Slider, Switch }
